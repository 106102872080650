<template>
    <div>
        <!-- Color -->
        <h1>Color</h1>
        <h3>{i} : 1 ~ 5</h3>
        <p>text-color : <u>{color}--text text--{lighten|darken|accent}-{i}</u></p>
        <p>background-color : <u>{color} {lighten|darken|accent}-{i}</u></p>
        <br />
        <br />

        <v-row>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px primary">primary</div>
                <div class="pa-10px primary lighten-5">lighten-5</div>
                <div class="pa-10px primary lighten-4">lighten-4</div>
                <div class="pa-10px primary lighten-3">lighten-3</div>
                <div class="pa-10px primary lighten-2">lighten-2</div>
                <div class="pa-10px primary lighten-1">lighten-1</div>
                <div class="pa-10px primary darken-1">darken-1</div>
                <div class="pa-10px primary darken-2">darken-2</div>
                <div class="pa-10px primary darken-3">darken-3</div>
                <div class="pa-10px primary darken-4">darken-4</div>
                <div class="pa-10px primary accent-1">accent-1</div>
                <div class="pa-10px primary accent-2">accent-2</div>
                <div class="pa-10px primary accent-3">accent-3</div>
                <div class="pa-10px primary accent-4">accent-4</div>
            </v-col>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px secondary">secondary</div>
                <div class="pa-10px secondary lighten-5">lighten-5</div>
                <div class="pa-10px secondary lighten-4">lighten-4</div>
                <div class="pa-10px secondary lighten-3">lighten-3</div>
                <div class="pa-10px secondary lighten-2">lighten-2</div>
                <div class="pa-10px secondary lighten-1">lighten-1</div>
                <div class="pa-10px secondary darken-1">darken-1</div>
                <div class="pa-10px secondary darken-2">darken-2</div>
                <div class="pa-10px secondary darken-3">darken-3</div>
                <div class="pa-10px secondary darken-4">darken-4</div>
                <div class="pa-10px secondary accent-1">accent-1</div>
                <div class="pa-10px secondary accent-2">accent-2</div>
                <div class="pa-10px secondary accent-3">accent-3</div>
                <div class="pa-10px secondary accent-4">accent-4</div>
            </v-col>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px accent">accent</div>
                <div class="pa-10px accent lighten-5">lighten-5</div>
                <div class="pa-10px accent lighten-4">lighten-4</div>
                <div class="pa-10px accent lighten-3">lighten-3</div>
                <div class="pa-10px accent lighten-2">lighten-2</div>
                <div class="pa-10px accent lighten-1">lighten-1</div>
                <div class="pa-10px accent darken-1">darken-1</div>
                <div class="pa-10px accent darken-2">darken-2</div>
                <div class="pa-10px accent darken-3">darken-3</div>
                <div class="pa-10px accent darken-4">darken-4</div>
                <div class="pa-10px accent accent-1">accent-1</div>
                <div class="pa-10px accent accent-2">accent-2</div>
                <div class="pa-10px accent accent-3">accent-3</div>
                <div class="pa-10px accent accent-4">accent-4</div>
            </v-col>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px success">success</div>
                <div class="pa-10px warning">warning</div>
                <div class="pa-10px error">error</div>
                <div class="pa-10px info">info</div>
            </v-col>
            <v-spacer />
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px red">red</div>
                <div class="pa-10px red lighten-5">lighten-5</div>
                <div class="pa-10px red lighten-4">lighten-4</div>
                <div class="pa-10px red lighten-3">lighten-3</div>
                <div class="pa-10px red lighten-2">lighten-2</div>
                <div class="pa-10px red lighten-1">lighten-1</div>
                <div class="pa-10px red darken-1">darken-1</div>
                <div class="pa-10px red darken-2">darken-2</div>
                <div class="pa-10px red darken-3">darken-3</div>
                <div class="pa-10px red darken-4">darken-4</div>
                <div class="pa-10px red accent-1">accent-1</div>
                <div class="pa-10px red accent-2">accent-2</div>
                <div class="pa-10px red accent-3">accent-3</div>
                <div class="pa-10px red accent-4">accent-4</div>
            </v-col>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px pink">pink</div>
                <div class="pa-10px pink lighten-5">lighten-5</div>
                <div class="pa-10px pink lighten-4">lighten-4</div>
                <div class="pa-10px pink lighten-3">lighten-3</div>
                <div class="pa-10px pink lighten-2">lighten-2</div>
                <div class="pa-10px pink lighten-1">lighten-1</div>
                <div class="pa-10px pink darken-1">darken-1</div>
                <div class="pa-10px pink darken-2">darken-2</div>
                <div class="pa-10px pink darken-3">darken-3</div>
                <div class="pa-10px pink darken-4">darken-4</div>
                <div class="pa-10px pink accent-1">accent-1</div>
                <div class="pa-10px pink accent-2">accent-2</div>
                <div class="pa-10px pink accent-3">accent-3</div>
                <div class="pa-10px pink accent-4">accent-4</div>
            </v-col>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px purple">purple</div>
                <div class="pa-10px purple lighten-5">lighten-5</div>
                <div class="pa-10px purple lighten-4">lighten-4</div>
                <div class="pa-10px purple lighten-3">lighten-3</div>
                <div class="pa-10px purple lighten-2">lighten-2</div>
                <div class="pa-10px purple lighten-1">lighten-1</div>
                <div class="pa-10px purple darken-1">darken-1</div>
                <div class="pa-10px purple darken-2">darken-2</div>
                <div class="pa-10px purple darken-3">darken-3</div>
                <div class="pa-10px purple darken-4">darken-4</div>
                <div class="pa-10px purple accent-1">accent-1</div>
                <div class="pa-10px purple accent-2">accent-2</div>
                <div class="pa-10px purple accent-3">accent-3</div>
                <div class="pa-10px purple accent-4">accent-4</div>
            </v-col>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px deep-purple">deep-purple</div>
                <div class="pa-10px deep-purple lighten-5">lighten-5</div>
                <div class="pa-10px deep-purple lighten-4">lighten-4</div>
                <div class="pa-10px deep-purple lighten-3">lighten-3</div>
                <div class="pa-10px deep-purple lighten-2">lighten-2</div>
                <div class="pa-10px deep-purple lighten-1">lighten-1</div>
                <div class="pa-10px deep-purple darken-1">darken-1</div>
                <div class="pa-10px deep-purple darken-2">darken-2</div>
                <div class="pa-10px deep-purple darken-3">darken-3</div>
                <div class="pa-10px deep-purple darken-4">darken-4</div>
                <div class="pa-10px deep-purple accent-1">accent-1</div>
                <div class="pa-10px deep-purple accent-2">accent-2</div>
                <div class="pa-10px deep-purple accent-3">accent-3</div>
                <div class="pa-10px deep-purple accent-4">accent-4</div>
            </v-col>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px indigo">indigo</div>
                <div class="pa-10px indigo lighten-5">lighten-5</div>
                <div class="pa-10px indigo lighten-4">lighten-4</div>
                <div class="pa-10px indigo lighten-3">lighten-3</div>
                <div class="pa-10px indigo lighten-2">lighten-2</div>
                <div class="pa-10px indigo lighten-1">lighten-1</div>
                <div class="pa-10px indigo darken-1">darken-1</div>
                <div class="pa-10px indigo darken-2">darken-2</div>
                <div class="pa-10px indigo darken-3">darken-3</div>
                <div class="pa-10px indigo darken-4">darken-4</div>
                <div class="pa-10px indigo accent-1">accent-1</div>
                <div class="pa-10px indigo accent-2">accent-2</div>
                <div class="pa-10px indigo accent-3">accent-3</div>
                <div class="pa-10px indigo accent-4">accent-4</div>
            </v-col>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px blue">blue</div>
                <div class="pa-10px blue lighten-5">lighten-5</div>
                <div class="pa-10px blue lighten-4">lighten-4</div>
                <div class="pa-10px blue lighten-3">lighten-3</div>
                <div class="pa-10px blue lighten-2">lighten-2</div>
                <div class="pa-10px blue lighten-1">lighten-1</div>
                <div class="pa-10px blue darken-1">darken-1</div>
                <div class="pa-10px blue darken-2">darken-2</div>
                <div class="pa-10px blue darken-3">darken-3</div>
                <div class="pa-10px blue darken-4">darken-4</div>
                <div class="pa-10px blue accent-1">accent-1</div>
                <div class="pa-10px blue accent-2">accent-2</div>
                <div class="pa-10px blue accent-3">accent-3</div>
                <div class="pa-10px blue accent-4">accent-4</div>
            </v-col>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px light-blue">light-blue</div>
                <div class="pa-10px light-blue lighten-5">lighten-5</div>
                <div class="pa-10px light-blue lighten-4">lighten-4</div>
                <div class="pa-10px light-blue lighten-3">lighten-3</div>
                <div class="pa-10px light-blue lighten-2">lighten-2</div>
                <div class="pa-10px light-blue lighten-1">lighten-1</div>
                <div class="pa-10px light-blue darken-1">darken-1</div>
                <div class="pa-10px light-blue darken-2">darken-2</div>
                <div class="pa-10px light-blue darken-3">darken-3</div>
                <div class="pa-10px light-blue darken-4">darken-4</div>
                <div class="pa-10px light-blue accent-1">accent-1</div>
                <div class="pa-10px light-blue accent-2">accent-2</div>
                <div class="pa-10px light-blue accent-3">accent-3</div>
                <div class="pa-10px light-blue accent-4">accent-4</div>
            </v-col>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px cyan">cyan</div>
                <div class="pa-10px cyan lighten-5">lighten-5</div>
                <div class="pa-10px cyan lighten-4">lighten-4</div>
                <div class="pa-10px cyan lighten-3">lighten-3</div>
                <div class="pa-10px cyan lighten-2">lighten-2</div>
                <div class="pa-10px cyan lighten-1">lighten-1</div>
                <div class="pa-10px cyan darken-1">darken-1</div>
                <div class="pa-10px cyan darken-2">darken-2</div>
                <div class="pa-10px cyan darken-3">darken-3</div>
                <div class="pa-10px cyan darken-4">darken-4</div>
                <div class="pa-10px cyan accent-1">accent-1</div>
                <div class="pa-10px cyan accent-2">accent-2</div>
                <div class="pa-10px cyan accent-3">accent-3</div>
                <div class="pa-10px cyan accent-4">accent-4</div>
            </v-col>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px teal">teal</div>
                <div class="pa-10px teal lighten-5">lighten-5</div>
                <div class="pa-10px teal lighten-4">lighten-4</div>
                <div class="pa-10px teal lighten-3">lighten-3</div>
                <div class="pa-10px teal lighten-2">lighten-2</div>
                <div class="pa-10px teal lighten-1">lighten-1</div>
                <div class="pa-10px teal darken-1">darken-1</div>
                <div class="pa-10px teal darken-2">darken-2</div>
                <div class="pa-10px teal darken-3">darken-3</div>
                <div class="pa-10px teal darken-4">darken-4</div>
                <div class="pa-10px teal accent-1">accent-1</div>
                <div class="pa-10px teal accent-2">accent-2</div>
                <div class="pa-10px teal accent-3">accent-3</div>
                <div class="pa-10px teal accent-4">accent-4</div>
            </v-col>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px green">green</div>
                <div class="pa-10px green lighten-5">lighten-5</div>
                <div class="pa-10px green lighten-4">lighten-4</div>
                <div class="pa-10px green lighten-3">lighten-3</div>
                <div class="pa-10px green lighten-2">lighten-2</div>
                <div class="pa-10px green lighten-1">lighten-1</div>
                <div class="pa-10px green darken-1">darken-1</div>
                <div class="pa-10px green darken-2">darken-2</div>
                <div class="pa-10px green darken-3">darken-3</div>
                <div class="pa-10px green darken-4">darken-4</div>
                <div class="pa-10px green accent-1">accent-1</div>
                <div class="pa-10px green accent-2">accent-2</div>
                <div class="pa-10px green accent-3">accent-3</div>
                <div class="pa-10px green accent-4">accent-4</div>
            </v-col>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px light-green">light-green</div>
                <div class="pa-10px light-green lighten-5">lighten-5</div>
                <div class="pa-10px light-green lighten-4">lighten-4</div>
                <div class="pa-10px light-green lighten-3">lighten-3</div>
                <div class="pa-10px light-green lighten-2">lighten-2</div>
                <div class="pa-10px light-green lighten-1">lighten-1</div>
                <div class="pa-10px light-green darken-1">darken-1</div>
                <div class="pa-10px light-green darken-2">darken-2</div>
                <div class="pa-10px light-green darken-3">darken-3</div>
                <div class="pa-10px light-green darken-4">darken-4</div>
                <div class="pa-10px light-green accent-1">accent-1</div>
                <div class="pa-10px light-green accent-2">accent-2</div>
                <div class="pa-10px light-green accent-3">accent-3</div>
                <div class="pa-10px light-green accent-4">accent-4</div>
            </v-col>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px lime">lime</div>
                <div class="pa-10px lime lighten-5">lighten-5</div>
                <div class="pa-10px lime lighten-4">lighten-4</div>
                <div class="pa-10px lime lighten-3">lighten-3</div>
                <div class="pa-10px lime lighten-2">lighten-2</div>
                <div class="pa-10px lime lighten-1">lighten-1</div>
                <div class="pa-10px lime darken-1">darken-1</div>
                <div class="pa-10px lime darken-2">darken-2</div>
                <div class="pa-10px lime darken-3">darken-3</div>
                <div class="pa-10px lime darken-4">darken-4</div>
                <div class="pa-10px lime accent-1">accent-1</div>
                <div class="pa-10px lime accent-2">accent-2</div>
                <div class="pa-10px lime accent-3">accent-3</div>
                <div class="pa-10px lime accent-4">accent-4</div>
            </v-col>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px yellow">yellow</div>
                <div class="pa-10px yellow lighten-5">lighten-5</div>
                <div class="pa-10px yellow lighten-4">lighten-4</div>
                <div class="pa-10px yellow lighten-3">lighten-3</div>
                <div class="pa-10px yellow lighten-2">lighten-2</div>
                <div class="pa-10px yellow lighten-1">lighten-1</div>
                <div class="pa-10px yellow darken-1">darken-1</div>
                <div class="pa-10px yellow darken-2">darken-2</div>
                <div class="pa-10px yellow darken-3">darken-3</div>
                <div class="pa-10px yellow darken-4">darken-4</div>
                <div class="pa-10px yellow accent-1">accent-1</div>
                <div class="pa-10px yellow accent-2">accent-2</div>
                <div class="pa-10px yellow accent-3">accent-3</div>
                <div class="pa-10px yellow accent-4">accent-4</div>
            </v-col>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px amber">amber</div>
                <div class="pa-10px amber lighten-5">lighten-5</div>
                <div class="pa-10px amber lighten-4">lighten-4</div>
                <div class="pa-10px amber lighten-3">lighten-3</div>
                <div class="pa-10px amber lighten-2">lighten-2</div>
                <div class="pa-10px amber lighten-1">lighten-1</div>
                <div class="pa-10px amber darken-1">darken-1</div>
                <div class="pa-10px amber darken-2">darken-2</div>
                <div class="pa-10px amber darken-3">darken-3</div>
                <div class="pa-10px amber darken-4">darken-4</div>
                <div class="pa-10px amber accent-1">accent-1</div>
                <div class="pa-10px amber accent-2">accent-2</div>
                <div class="pa-10px amber accent-3">accent-3</div>
                <div class="pa-10px amber accent-4">accent-4</div>
            </v-col>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px orange">orange</div>
                <div class="pa-10px orange lighten-5">lighten-5</div>
                <div class="pa-10px orange lighten-4">lighten-4</div>
                <div class="pa-10px orange lighten-3">lighten-3</div>
                <div class="pa-10px orange lighten-2">lighten-2</div>
                <div class="pa-10px orange lighten-1">lighten-1</div>
                <div class="pa-10px orange darken-1">darken-1</div>
                <div class="pa-10px orange darken-2">darken-2</div>
                <div class="pa-10px orange darken-3">darken-3</div>
                <div class="pa-10px orange darken-4">darken-4</div>
                <div class="pa-10px orange accent-1">accent-1</div>
                <div class="pa-10px orange accent-2">accent-2</div>
                <div class="pa-10px orange accent-3">accent-3</div>
                <div class="pa-10px orange accent-4">accent-4</div>
            </v-col>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px deep-orange">deep-orange</div>
                <div class="pa-10px deep-orange lighten-5">lighten-5</div>
                <div class="pa-10px deep-orange lighten-4">lighten-4</div>
                <div class="pa-10px deep-orange lighten-3">lighten-3</div>
                <div class="pa-10px deep-orange lighten-2">lighten-2</div>
                <div class="pa-10px deep-orange lighten-1">lighten-1</div>
                <div class="pa-10px deep-orange darken-1">darken-1</div>
                <div class="pa-10px deep-orange darken-2">darken-2</div>
                <div class="pa-10px deep-orange darken-3">darken-3</div>
                <div class="pa-10px deep-orange darken-4">darken-4</div>
                <div class="pa-10px deep-orange accent-1">accent-1</div>
                <div class="pa-10px deep-orange accent-2">accent-2</div>
                <div class="pa-10px deep-orange accent-3">accent-3</div>
                <div class="pa-10px deep-orange accent-4">accent-4</div>
            </v-col>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px brown">brown</div>
                <div class="pa-10px brown lighten-5">lighten-5</div>
                <div class="pa-10px brown lighten-4">lighten-4</div>
                <div class="pa-10px brown lighten-3">lighten-3</div>
                <div class="pa-10px brown lighten-2">lighten-2</div>
                <div class="pa-10px brown lighten-1">lighten-1</div>
                <div class="pa-10px brown darken-1">darken-1</div>
                <div class="pa-10px brown darken-2">darken-2</div>
                <div class="pa-10px brown darken-3">darken-3</div>
                <div class="pa-10px brown darken-4">darken-4</div>
            </v-col>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px blue-grey">blue-grey</div>
                <div class="pa-10px blue-grey lighten-5">lighten-5</div>
                <div class="pa-10px blue-grey lighten-4">lighten-4</div>
                <div class="pa-10px blue-grey lighten-3">lighten-3</div>
                <div class="pa-10px blue-grey lighten-2">lighten-2</div>
                <div class="pa-10px blue-grey lighten-1">lighten-1</div>
                <div class="pa-10px blue-grey darken-1">darken-1</div>
                <div class="pa-10px blue-grey darken-2">darken-2</div>
                <div class="pa-10px blue-grey darken-3">darken-3</div>
                <div class="pa-10px blue-grey darken-4">darken-4</div>
            </v-col>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px grey">grey</div>
                <div class="pa-10px grey lighten-5">lighten-5</div>
                <div class="pa-10px grey lighten-4">lighten-4</div>
                <div class="pa-10px grey lighten-3">lighten-3</div>
                <div class="pa-10px grey lighten-2">lighten-2</div>
                <div class="pa-10px grey lighten-1">lighten-1</div>
                <div class="pa-10px grey darken-1">darken-1</div>
                <div class="pa-10px grey darken-2">darken-2</div>
                <div class="pa-10px grey darken-3">darken-3</div>
                <div class="pa-10px grey darken-4">darken-4</div>
            </v-col>
            <v-col cols="6" md="4" lg="2">
                <div class="pa-10px black">black</div>
                <div class="pa-10px white">white</div>
                <div class="pa-10px transparent">transparent</div>
            </v-col>
        </v-row>

        <!-- // Color -->
    </div>
</template>

<script>
import GuideApp from "./guide-app.vue";

export default {
    props: {},
    components: {
        GuideApp,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>