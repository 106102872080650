<template>
    <div>
        <v-tabs v-model="tab" grow class="mb-30px">
            <v-tab>Title wrap</v-tab>
            <v-tab>Title</v-tab>
            <v-tab>Text</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item><typography-title-wrap /></v-tab-item>
            <v-tab-item><typography-title /></v-tab-item>
            <v-tab-item><typography-text /></v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import GuideApp from "./guide-app.vue";

import TypographyTitleWrap from "./typography-title-wrap.vue";
import TypographyTitle from "./typography-title.vue";
import TypographyText from "./typography-text.vue";

export default {
    props: {},
    components: {
        GuideApp,

        TypographyTitleWrap,
        TypographyTitle,
        TypographyText,
    },
    data() {
        return {
            tab: null,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
