var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-tabs', _vm._b({}, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common, _vm.$attrs), false), [_c('tab-primary', [_vm._v("Tab Primary1")]), _c('tab-primary', [_vm._v("Tab Primary2")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-tabs', _vm._b({}, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common, _vm.$attrs), false), [_c('tab-secondary', [_vm._v("Tab Secondary1")]), _c('tab-secondary', [_vm._v("Tab Secondary2")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-tabs', _vm._b({}, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common, _vm.$attrs), false), [_c('tab-tertiary', [_vm._v("Tab Tertiary1")]), _c('tab-tertiary', [_vm._v("Tab Tertiary2")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-tabs', _vm._b({}, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common, _vm.$attrs), false), [_c('tab-quarternary', [_vm._v("Tab Quarternary1")]), _c('tab-quarternary', [_vm._v("Tab Quarternary2")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _c('h2', [_vm._v("Filled")]), _vm._m(1), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', _vm._b({}, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common, _vm.$attrs), false), [_c('u-tab-filled', [_vm._v("Tab")]), _c('u-tab-filled', [_vm._v("Tab")]), _c('u-tab-filled', [_vm._v("Tab")]), _c('u-tab-filled', [_vm._v("Tab")]), _c('u-tab-filled', [_vm._v("Tab")])], 1)], 1)], 1), _c('br'), _c('h2', [_vm._v("Slider")]), _vm._m(2), _c('p', [_vm._v("u-tabs-slider와 스타일이 다릅니다.")]), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', _vm._b({}, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common, _vm.$attrs), false), [_c('u-tab-slider', [_vm._v("Tab")]), _c('u-tab-slider', [_vm._v("Tab")]), _c('u-tab-slider', [_vm._v("Tab")]), _c('u-tab-slider', [_vm._v("Tab")]), _c('u-tab-slider', [_vm._v("Tab")])], 1)], 1)], 1), _c('br'), _c('h2', [_vm._v("Dot")]), _vm._m(3), _vm._m(4), _c('p', [_vm._v("u-tabs-dot, u-tab-dot 세트로 사용합니다.")]), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-tabs-dot', [_c('u-tab-dot', [_vm._v("Tab")]), _c('u-tab-dot', [_vm._v("Tab")]), _c('u-tab-dot', [_vm._v("Tab")]), _c('u-tab-dot', [_vm._v("Tab")]), _c('u-tab-dot', [_vm._v("Tab")])], 1)], 1)], 1), _c('br'), _c('h2', [_vm._v("Button")]), _vm._m(5), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', _vm._b({}, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common, _vm.$attrs), false), [_c('u-tab-button', [_vm._v("Tab")]), _c('u-tab-button', [_vm._v("Tab")]), _c('u-tab-button', [_vm._v("Tab")]), _c('u-tab-button', [_vm._v("Tab")]), _c('u-tab-button', [_vm._v("Tab")])], 1)], 1)], 1), _c('br'), _c('h2', [_vm._v("Punch")]), _vm._m(6), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', _vm._b({}, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common, _vm.$attrs), false), [_c('u-tab-punch', [_vm._v("Tab")]), _c('u-tab-punch', [_vm._v("Tab")]), _c('u-tab-punch', [_vm._v("Tab")]), _c('u-tab-punch', [_vm._v("Tab")]), _c('u-tab-punch', [_vm._v("Tab")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("tab-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-tab-filled")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-tab-slider")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-tabs-dot")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-tab-dot")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-tab-button")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-tab-punch")])]);

}]

export { render, staticRenderFns }