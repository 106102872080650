<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>btn-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <btn-primary @click="clickTest"><u-icon class="v-icon--left">favorite</u-icon>버튼</btn-primary>
            </v-col>
            <v-col cols="auto">
                <btn-primary2 @click="clickTest">버튼</btn-primary2>
            </v-col>
            <v-col cols="auto">
                <btn-primary3 @click="clickTest">버튼</btn-primary3>
            </v-col>
            <v-col cols="auto">
                <btn-secondary x-large @click="clickTest">버튼</btn-secondary>
            </v-col>
            <v-col cols="auto">
                <btn-secondary2 x-large @click="clickTest">버튼</btn-secondary2>
            </v-col>
            <v-col cols="auto">
                <btn-secondary3 x-large @click="clickTest">버튼</btn-secondary3>
            </v-col>
            <v-col cols="auto">
                <btn-tertiary @click="clickTest">버튼<u-icon class="v-icon--right">check</u-icon></btn-tertiary>
            </v-col>
            <v-col cols="auto">
                <btn-tertiary2 @click="clickTest">버튼</btn-tertiary2>
            </v-col>
            <v-col cols="auto">
                <btn-tertiary3 @click="clickTest">버튼</btn-tertiary3>
            </v-col>
            <v-col cols="auto">
                <btn-quarternary @click="clickTest">버튼</btn-quarternary>
            </v-col>
            <v-col cols="auto">
                <btn-quarternary2 @click="clickTest">버튼</btn-quarternary2>
            </v-col>
            <v-col cols="auto">
                <btn-quarternary3 @click="clickTest">버튼</btn-quarternary3>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Flat -->
        <h2>Flat</h2>
        <p><u>u-btn-flat-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-btn-flat-tile>Flat Tile</u-btn-flat-tile>
            </v-col>
            <v-col cols="auto">
                <u-btn-flat-rounded>Flat Rounded</u-btn-flat-rounded>
            </v-col>
            <v-col cols="auto">
                <u-btn-flat-pill>Flat Pill</u-btn-flat-pill>
            </v-col>
        </v-row>
        <!-- E: Flat -->
        <br />

        <!-- S: Outlined -->
        <h2>Outlined</h2>
        <p><u>u-btn-outlined-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-btn-outlined-tile>Outlined Tile</u-btn-outlined-tile>
            </v-col>
            <v-col cols="auto">
                <u-btn-outlined-rounded>Outlined Rounded</u-btn-outlined-rounded>
            </v-col>
            <v-col cols="auto">
                <u-btn-outlined-pill>Outlined Pill</u-btn-outlined-pill>
            </v-col>
        </v-row>
        <!-- E: Outlined -->
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Variant -->
        <h1>Variant</h1>
        <h3>{variant} : (기본), elevation, outlined</h3>
        <p><u>{variant}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-btn color="primary">Default</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn elevation="10" color="white">Elevation</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn outlined color="primary">Outlined</v-btn>
            </v-col>
        </v-row>
        <!-- // Variant -->
    </div>
</template>

<script>
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";
import BtnPrimary2 from "@/components/publish/parents/buttons/btn-primary2.vue";
import BtnPrimary3 from "@/components/publish/parents/buttons/btn-primary3.vue";
import BtnSecondary from "@/components/publish/parents/buttons/btn-secondary.vue";
import BtnSecondary2 from "@/components/publish/parents/buttons/btn-secondary2.vue";
import BtnSecondary3 from "@/components/publish/parents/buttons/btn-secondary3.vue";
import BtnTertiary from "@/components/publish/parents/buttons/btn-tertiary.vue";
import BtnTertiary2 from "@/components/publish/parents/buttons/btn-tertiary2.vue";
import BtnTertiary3 from "@/components/publish/parents/buttons/btn-tertiary3.vue";
import BtnQuarternary from "@/components/publish/parents/buttons/btn-quarternary.vue";
import BtnQuarternary2 from "@/components/publish/parents/buttons/btn-quarternary2.vue";
import BtnQuarternary3 from "@/components/publish/parents/buttons/btn-quarternary3.vue";

import UBtnFlatTile from "@/components/publish/styles/buttons/u-btn-flat-tile.vue";
import UBtnFlatRounded from "@/components/publish/styles/buttons/u-btn-flat-rounded.vue";
import UBtnFlatPill from "@/components/publish/styles/buttons/u-btn-flat-pill.vue";
import UBtnOutlinedTile from "@/components/publish/styles/buttons/u-btn-outlined-tile.vue";
import UBtnOutlinedRounded from "@/components/publish/styles/buttons/u-btn-outlined-rounded.vue";
import UBtnOutlinedPill from "@/components/publish/styles/buttons/u-btn-outlined-pill.vue";

import UIcon from "@/components/publish/styles/icons/u-icon.vue";

export default {
    props: {},
    components: {
        BtnPrimary,
        BtnPrimary2,
        BtnPrimary3,
        BtnSecondary,
        BtnSecondary2,
        BtnSecondary3,
        BtnTertiary,
        BtnTertiary2,
        BtnTertiary3,
        BtnQuarternary,
        BtnQuarternary2,
        BtnQuarternary3,

        UBtnFlatTile,
        UBtnFlatRounded,
        UBtnFlatPill,
        UBtnOutlinedTile,
        UBtnOutlinedRounded,
        UBtnOutlinedPill,

        UIcon,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {
        clickTest() {
            alert("TEST");
        },
    },
};
</script>

<style lang="scss" scoped></style>
