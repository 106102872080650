var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('txt-primary', [_vm._v("내용을입력해주세요")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('txt-secondary', [_vm._v("내용을입력해주세요")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('txt-tertiary', [_c('p', [_vm._v("내용을입력해주세요")]), _c('p', [_vm._v("내용을입력해주세요")]), _c('p', [_vm._v("내용을입력해주세요")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('txt-quarternary', [_c('p', [_vm._v("내용을입력해주세요")]), _c('p', [_vm._v("내용을입력해주세요")]), _c('p', [_vm._v("내용을입력해주세요")])])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _c('h2', [_vm._v("Default")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('p', [_c('u', [_vm._v("u-txt-default")])]), _c('br'), _c('u-txt-default', [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")])], 1)], 1), _c('br'), _c('h2', [_vm._v("Medium")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('p', [_c('u', [_vm._v("u-txt-medium")])]), _c('br'), _c('u-txt-medium', [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Size")]), _c('h3', [_vm._v("{size} : xs, sm, (기본), lg, xl")]), _vm._m(1), _c('br'), _c('txt', {
    staticClass: "txt--xs"
  }, [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")]), _c('txt', {
    staticClass: "txt--sm"
  }, [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")]), _c('txt', [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")]), _c('txt', {
    staticClass: "txt--lg"
  }, [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")]), _c('txt', {
    staticClass: "txt--xl"
  }, [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")]), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Color")]), _c('h3', [_vm._v("{color} : (기본), light, dark")]), _vm._m(2), _c('br'), _c('txt', [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")]), _c('txt', {
    staticClass: "txt--light"
  }, [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")]), _c('txt', {
    staticClass: "txt--dark"
  }, [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")]), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("단락")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h2', [_vm._v("구분이 없는 경우")]), _c('br'), _c('txt', [_vm._v(" 동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세 남산 위에 저 소나무 철갑을 두른 듯 바람 서리 불변함은 우리기상일세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세 가을 하늘 공활한데 높고 구름 없이 밝은 달은 우리 가슴 일편단심일세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세 이 기상과 이 맘으로 충성을 다하여 괴로우나 즐거우나 나라 사랑하세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h2', [_vm._v("구분이 있는 경우")]), _c('br'), _c('txt', [_c('p', [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")]), _c('p', [_vm._v("남산 위에 저 소나무 철갑을 두른 듯 바람 서리 불변함은 우리 기상일세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")]), _c('p', [_vm._v("가을 하늘 공활한데 높고 구름 없이 밝은 달은 우리 가슴 일편단심일세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")])])], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("txt-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("txt--{size}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("txt--{color}")])]);

}]

export { render, staticRenderFns }