var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _c('h2', [_vm._v("Arrow")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-primary')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-secondary', {
    attrs: {
      "direction": "right"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-tertiary', {
    attrs: {
      "direction": "left"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-quarternary', {
    attrs: {
      "direction": "right"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Color")]), _vm._m(1), _c('br'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-icon', {
    attrs: {
      "color": "primary--text"
    }
  }, [_vm._v("shopping_cart")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-icon', {
    attrs: {
      "color": "secondary--text"
    }
  }, [_vm._v("shopping_cart")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-icon', {
    attrs: {
      "color": "grey--text"
    }
  }, [_vm._v("shopping_cart")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "color": "yellow--text"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow', {
    attrs: {
      "color": "teal--text"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long-rounded', {
    attrs: {
      "color": "light-blue--text"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Vuetify Icons")]), _c('h2', [_vm._v("u-icon")]), _c('p', [_vm._v("vue에서 렌더링 하는데 소모하는 시간이 있어 v-icon을 사용하지 않고 i 태그를 이용합니다.")]), _c('p', [_vm._v("Material Icons 아이콘은 되도록이면 u-icon를 이용해 주세요.")]), _vm._m(2), _vm._m(3), _c('br'), _c('h2', [_vm._v("Variant")]), _c('h3', [_vm._v("{variant} : (기본-filled), outlined, round, sharp, two-tone")]), _vm._m(4), _c('br'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-icon', [_vm._v("shopping_cart")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-icon', {
    attrs: {
      "variant": "outlined"
    }
  }, [_vm._v("shopping_cart")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-icon', {
    attrs: {
      "variant": "round"
    }
  }, [_vm._v("shopping_cart")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-icon', {
    attrs: {
      "variant": "sharp"
    }
  }, [_vm._v("shopping_cart")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-icon', {
    attrs: {
      "variant": "two-tone"
    }
  }, [_vm._v("shopping_cart")])], 1)], 1), _c('br'), _c('h2', [_vm._v("Size")]), _c('h3', [_vm._v("{size} : xx-small, x-small, small, (default), large, x-large, xx-large")]), _vm._m(5), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-icon', {
    attrs: {
      "size": "xx-small"
    }
  }, [_vm._v("shopping_cart")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-icon', {
    attrs: {
      "size": "x-small"
    }
  }, [_vm._v("shopping_cart")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-icon', {
    attrs: {
      "size": "small"
    }
  }, [_vm._v("shopping_cart")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-icon', [_vm._v("shopping_cart")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-icon', {
    attrs: {
      "size": "large"
    }
  }, [_vm._v("shopping_cart")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-icon', {
    attrs: {
      "size": "x-large"
    }
  }, [_vm._v("shopping_cart")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-icon', {
    attrs: {
      "size": "xx-large"
    }
  }, [_vm._v("shopping_cart")])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Custom Icons")]), _c('h2', [_vm._v("Arrow")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h3', [_vm._v("{size} : xx-small, x-small, small, (default), large, x-large, xx-large")]), _c('p', [_c('u', [_vm._v("size=\"{size}\"")])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h3', [_vm._v("{direction} : (left), right, up, down")]), _c('p', [_c('u', [_vm._v("direction=\"{direction}\"")])])])], 1), _c('br'), _c('h3', [_vm._v("icon-chevron")]), _c('br'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "size": "xx-small"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "size": "x-small"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "size": "small"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-chevron')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "size": "large"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "size": "x-large"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "size": "xx-large"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-chevron')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "direction": "right"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "direction": "up"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "direction": "down"
    }
  })], 1)], 1)], 1)], 1), _c('br'), _c('h3', [_vm._v("icon-arrow")]), _c('br'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow', {
    attrs: {
      "size": "xx-small"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow', {
    attrs: {
      "size": "x-small"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow', {
    attrs: {
      "size": "small"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow', {
    attrs: {
      "size": "large"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow', {
    attrs: {
      "size": "x-large"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow', {
    attrs: {
      "size": "xx-large"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow', {
    attrs: {
      "direction": "right"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow', {
    attrs: {
      "direction": "up"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow', {
    attrs: {
      "direction": "down"
    }
  })], 1)], 1)], 1)], 1), _c('br'), _c('h3', [_vm._v("icon-arrow-rounded")]), _c('br'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "size": "xx-small"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "size": "x-small"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "size": "small"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-rounded')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "size": "large"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "size": "x-large"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "size": "xx-large"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-rounded')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "direction": "right"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "direction": "up"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "direction": "down"
    }
  })], 1)], 1)], 1)], 1), _c('br'), _c('h3', [_vm._v("icon-arrow-long")]), _c('br'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "size": "xx-small"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "size": "x-small"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "size": "small"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "size": "large"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "size": "x-large"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "size": "xx-large"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "direction": "right"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "direction": "up"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "direction": "down"
    }
  })], 1)], 1)], 1)], 1), _c('br'), _c('h3', [_vm._v("icon-arrow-long-rounded")]), _c('br'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long-rounded', {
    attrs: {
      "size": "xx-small"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long-rounded', {
    attrs: {
      "size": "x-small"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long-rounded', {
    attrs: {
      "size": "small"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long-rounded')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long-rounded', {
    attrs: {
      "size": "large"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long-rounded', {
    attrs: {
      "size": "x-large"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long-rounded', {
    attrs: {
      "size": "xx-large"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long-rounded')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long-rounded', {
    attrs: {
      "direction": "right"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long-rounded', {
    attrs: {
      "direction": "up"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-long-rounded', {
    attrs: {
      "direction": "down"
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("icon-arrow-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("color=\"{color}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', [_vm._v("{icon} : "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://fonts.google.com/icons?icon.style=Filled&icon.set=Material+Icons"
    }
  }, [_vm._v("Material Icons")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("<u-icon>{icon}</u-icon>")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("variant=\"{variant}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("size=\"{size}\"")])]);

}]

export { render, staticRenderFns }