var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-tabs', {
    staticClass: "mb-30px",
    attrs: {
      "grow": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("Tabs, Windows")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Template")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('tabs-tabs')], 1), _c('v-tab-item', [_c('tabs-tab')], 1), _c('v-tab-item', [_c('tabs-template')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }