var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-hamburger-primary')], 1), _c('v-col', {
    staticClass: "grey",
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-hamburger-secondary')], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _c('h2', [_vm._v("Basic")]), _vm._m(1), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-hamburger-basic')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-hamburger-basic', {
    staticClass: "active"
  })], 1)], 1), _c('br'), _c('h2', [_vm._v("Concave")]), _vm._m(2), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-hamburger-concave')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-hamburger-concave', {
    staticClass: "active"
  })], 1)], 1), _c('br'), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Color")]), _vm._m(3), _c('br'), _c('div', {
    staticClass: "grey pa-20px"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-hamburger-basic', {
    staticClass: "btn-hamburger--light"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-hamburger-concave', {
    staticClass: "btn-hamburger--light"
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("btn-hamburger-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-btn-hamburger-basic")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-btn-hamburger-concave")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("btn-hamburger--light")])]);

}]

export { render, staticRenderFns }