var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-icon', _vm._b({}, 'u-icon', _vm.$attrs, false), [_vm._v("arrow_" + _vm._s(_vm.direction))]);

}
var staticRenderFns = []

export { render, staticRenderFns }