<template>
    <v-card v-bind="$attrs" outlined rounded="0">
        <v-divider class="border-4" />
        <slot />
    </v-card>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
