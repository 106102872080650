<template>
    <txt v-bind="$attrs"><slot /></txt>
</template>

<script>
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        Txt,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
