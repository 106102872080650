var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-tabs', {
    staticClass: "mb-30px",
    attrs: {
      "grow": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("Common")]), _c('v-tab', [_vm._v("Default")]), _c('v-tab', [_vm._v("Text")]), _c('v-tab', [_vm._v("Icon")]), _c('v-tab', [_vm._v("Arrow")]), _c('v-tab', [_vm._v("Top")]), _c('v-tab', [_vm._v("Hamburger")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('buttons-common')], 1), _c('v-tab-item', [_c('buttons-default')], 1), _c('v-tab-item', [_c('buttons-text')], 1), _c('v-tab-item', [_c('buttons-icon')], 1), _c('v-tab-item', [_c('buttons-arrow')], 1), _c('v-tab-item', [_c('buttons-top')], 1), _c('v-tab-item', [_c('buttons-hamburger')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }