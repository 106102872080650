var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Color")]), _c('h3', [_vm._v("{i} : 1 ~ 5")]), _vm._m(0), _vm._m(1), _c('br'), _c('br'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px primary"
  }, [_vm._v("primary")]), _c('div', {
    staticClass: "pa-10px primary lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px primary lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px primary lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px primary lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px primary lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px primary darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px primary darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px primary darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px primary darken-4"
  }, [_vm._v("darken-4")]), _c('div', {
    staticClass: "pa-10px primary accent-1"
  }, [_vm._v("accent-1")]), _c('div', {
    staticClass: "pa-10px primary accent-2"
  }, [_vm._v("accent-2")]), _c('div', {
    staticClass: "pa-10px primary accent-3"
  }, [_vm._v("accent-3")]), _c('div', {
    staticClass: "pa-10px primary accent-4"
  }, [_vm._v("accent-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px secondary"
  }, [_vm._v("secondary")]), _c('div', {
    staticClass: "pa-10px secondary lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px secondary lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px secondary lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px secondary lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px secondary lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px secondary darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px secondary darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px secondary darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px secondary darken-4"
  }, [_vm._v("darken-4")]), _c('div', {
    staticClass: "pa-10px secondary accent-1"
  }, [_vm._v("accent-1")]), _c('div', {
    staticClass: "pa-10px secondary accent-2"
  }, [_vm._v("accent-2")]), _c('div', {
    staticClass: "pa-10px secondary accent-3"
  }, [_vm._v("accent-3")]), _c('div', {
    staticClass: "pa-10px secondary accent-4"
  }, [_vm._v("accent-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px accent"
  }, [_vm._v("accent")]), _c('div', {
    staticClass: "pa-10px accent lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px accent lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px accent lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px accent lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px accent lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px accent darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px accent darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px accent darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px accent darken-4"
  }, [_vm._v("darken-4")]), _c('div', {
    staticClass: "pa-10px accent accent-1"
  }, [_vm._v("accent-1")]), _c('div', {
    staticClass: "pa-10px accent accent-2"
  }, [_vm._v("accent-2")]), _c('div', {
    staticClass: "pa-10px accent accent-3"
  }, [_vm._v("accent-3")]), _c('div', {
    staticClass: "pa-10px accent accent-4"
  }, [_vm._v("accent-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px success"
  }, [_vm._v("success")]), _c('div', {
    staticClass: "pa-10px warning"
  }, [_vm._v("warning")]), _c('div', {
    staticClass: "pa-10px error"
  }, [_vm._v("error")]), _c('div', {
    staticClass: "pa-10px info"
  }, [_vm._v("info")])]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px red"
  }, [_vm._v("red")]), _c('div', {
    staticClass: "pa-10px red lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px red lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px red lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px red lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px red lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px red darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px red darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px red darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px red darken-4"
  }, [_vm._v("darken-4")]), _c('div', {
    staticClass: "pa-10px red accent-1"
  }, [_vm._v("accent-1")]), _c('div', {
    staticClass: "pa-10px red accent-2"
  }, [_vm._v("accent-2")]), _c('div', {
    staticClass: "pa-10px red accent-3"
  }, [_vm._v("accent-3")]), _c('div', {
    staticClass: "pa-10px red accent-4"
  }, [_vm._v("accent-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px pink"
  }, [_vm._v("pink")]), _c('div', {
    staticClass: "pa-10px pink lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px pink lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px pink lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px pink lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px pink lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px pink darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px pink darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px pink darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px pink darken-4"
  }, [_vm._v("darken-4")]), _c('div', {
    staticClass: "pa-10px pink accent-1"
  }, [_vm._v("accent-1")]), _c('div', {
    staticClass: "pa-10px pink accent-2"
  }, [_vm._v("accent-2")]), _c('div', {
    staticClass: "pa-10px pink accent-3"
  }, [_vm._v("accent-3")]), _c('div', {
    staticClass: "pa-10px pink accent-4"
  }, [_vm._v("accent-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px purple"
  }, [_vm._v("purple")]), _c('div', {
    staticClass: "pa-10px purple lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px purple lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px purple lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px purple lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px purple lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px purple darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px purple darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px purple darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px purple darken-4"
  }, [_vm._v("darken-4")]), _c('div', {
    staticClass: "pa-10px purple accent-1"
  }, [_vm._v("accent-1")]), _c('div', {
    staticClass: "pa-10px purple accent-2"
  }, [_vm._v("accent-2")]), _c('div', {
    staticClass: "pa-10px purple accent-3"
  }, [_vm._v("accent-3")]), _c('div', {
    staticClass: "pa-10px purple accent-4"
  }, [_vm._v("accent-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px deep-purple"
  }, [_vm._v("deep-purple")]), _c('div', {
    staticClass: "pa-10px deep-purple lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px deep-purple lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px deep-purple lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px deep-purple lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px deep-purple lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px deep-purple darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px deep-purple darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px deep-purple darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px deep-purple darken-4"
  }, [_vm._v("darken-4")]), _c('div', {
    staticClass: "pa-10px deep-purple accent-1"
  }, [_vm._v("accent-1")]), _c('div', {
    staticClass: "pa-10px deep-purple accent-2"
  }, [_vm._v("accent-2")]), _c('div', {
    staticClass: "pa-10px deep-purple accent-3"
  }, [_vm._v("accent-3")]), _c('div', {
    staticClass: "pa-10px deep-purple accent-4"
  }, [_vm._v("accent-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px indigo"
  }, [_vm._v("indigo")]), _c('div', {
    staticClass: "pa-10px indigo lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px indigo lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px indigo lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px indigo lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px indigo lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px indigo darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px indigo darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px indigo darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px indigo darken-4"
  }, [_vm._v("darken-4")]), _c('div', {
    staticClass: "pa-10px indigo accent-1"
  }, [_vm._v("accent-1")]), _c('div', {
    staticClass: "pa-10px indigo accent-2"
  }, [_vm._v("accent-2")]), _c('div', {
    staticClass: "pa-10px indigo accent-3"
  }, [_vm._v("accent-3")]), _c('div', {
    staticClass: "pa-10px indigo accent-4"
  }, [_vm._v("accent-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px blue"
  }, [_vm._v("blue")]), _c('div', {
    staticClass: "pa-10px blue lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px blue lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px blue lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px blue lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px blue lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px blue darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px blue darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px blue darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px blue darken-4"
  }, [_vm._v("darken-4")]), _c('div', {
    staticClass: "pa-10px blue accent-1"
  }, [_vm._v("accent-1")]), _c('div', {
    staticClass: "pa-10px blue accent-2"
  }, [_vm._v("accent-2")]), _c('div', {
    staticClass: "pa-10px blue accent-3"
  }, [_vm._v("accent-3")]), _c('div', {
    staticClass: "pa-10px blue accent-4"
  }, [_vm._v("accent-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px light-blue"
  }, [_vm._v("light-blue")]), _c('div', {
    staticClass: "pa-10px light-blue lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px light-blue lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px light-blue lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px light-blue lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px light-blue lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px light-blue darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px light-blue darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px light-blue darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px light-blue darken-4"
  }, [_vm._v("darken-4")]), _c('div', {
    staticClass: "pa-10px light-blue accent-1"
  }, [_vm._v("accent-1")]), _c('div', {
    staticClass: "pa-10px light-blue accent-2"
  }, [_vm._v("accent-2")]), _c('div', {
    staticClass: "pa-10px light-blue accent-3"
  }, [_vm._v("accent-3")]), _c('div', {
    staticClass: "pa-10px light-blue accent-4"
  }, [_vm._v("accent-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px cyan"
  }, [_vm._v("cyan")]), _c('div', {
    staticClass: "pa-10px cyan lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px cyan lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px cyan lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px cyan lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px cyan lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px cyan darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px cyan darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px cyan darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px cyan darken-4"
  }, [_vm._v("darken-4")]), _c('div', {
    staticClass: "pa-10px cyan accent-1"
  }, [_vm._v("accent-1")]), _c('div', {
    staticClass: "pa-10px cyan accent-2"
  }, [_vm._v("accent-2")]), _c('div', {
    staticClass: "pa-10px cyan accent-3"
  }, [_vm._v("accent-3")]), _c('div', {
    staticClass: "pa-10px cyan accent-4"
  }, [_vm._v("accent-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px teal"
  }, [_vm._v("teal")]), _c('div', {
    staticClass: "pa-10px teal lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px teal lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px teal lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px teal lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px teal lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px teal darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px teal darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px teal darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px teal darken-4"
  }, [_vm._v("darken-4")]), _c('div', {
    staticClass: "pa-10px teal accent-1"
  }, [_vm._v("accent-1")]), _c('div', {
    staticClass: "pa-10px teal accent-2"
  }, [_vm._v("accent-2")]), _c('div', {
    staticClass: "pa-10px teal accent-3"
  }, [_vm._v("accent-3")]), _c('div', {
    staticClass: "pa-10px teal accent-4"
  }, [_vm._v("accent-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px green"
  }, [_vm._v("green")]), _c('div', {
    staticClass: "pa-10px green lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px green lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px green lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px green lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px green lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px green darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px green darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px green darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px green darken-4"
  }, [_vm._v("darken-4")]), _c('div', {
    staticClass: "pa-10px green accent-1"
  }, [_vm._v("accent-1")]), _c('div', {
    staticClass: "pa-10px green accent-2"
  }, [_vm._v("accent-2")]), _c('div', {
    staticClass: "pa-10px green accent-3"
  }, [_vm._v("accent-3")]), _c('div', {
    staticClass: "pa-10px green accent-4"
  }, [_vm._v("accent-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px light-green"
  }, [_vm._v("light-green")]), _c('div', {
    staticClass: "pa-10px light-green lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px light-green lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px light-green lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px light-green lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px light-green lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px light-green darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px light-green darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px light-green darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px light-green darken-4"
  }, [_vm._v("darken-4")]), _c('div', {
    staticClass: "pa-10px light-green accent-1"
  }, [_vm._v("accent-1")]), _c('div', {
    staticClass: "pa-10px light-green accent-2"
  }, [_vm._v("accent-2")]), _c('div', {
    staticClass: "pa-10px light-green accent-3"
  }, [_vm._v("accent-3")]), _c('div', {
    staticClass: "pa-10px light-green accent-4"
  }, [_vm._v("accent-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px lime"
  }, [_vm._v("lime")]), _c('div', {
    staticClass: "pa-10px lime lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px lime lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px lime lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px lime lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px lime lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px lime darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px lime darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px lime darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px lime darken-4"
  }, [_vm._v("darken-4")]), _c('div', {
    staticClass: "pa-10px lime accent-1"
  }, [_vm._v("accent-1")]), _c('div', {
    staticClass: "pa-10px lime accent-2"
  }, [_vm._v("accent-2")]), _c('div', {
    staticClass: "pa-10px lime accent-3"
  }, [_vm._v("accent-3")]), _c('div', {
    staticClass: "pa-10px lime accent-4"
  }, [_vm._v("accent-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px yellow"
  }, [_vm._v("yellow")]), _c('div', {
    staticClass: "pa-10px yellow lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px yellow lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px yellow lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px yellow lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px yellow lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px yellow darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px yellow darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px yellow darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px yellow darken-4"
  }, [_vm._v("darken-4")]), _c('div', {
    staticClass: "pa-10px yellow accent-1"
  }, [_vm._v("accent-1")]), _c('div', {
    staticClass: "pa-10px yellow accent-2"
  }, [_vm._v("accent-2")]), _c('div', {
    staticClass: "pa-10px yellow accent-3"
  }, [_vm._v("accent-3")]), _c('div', {
    staticClass: "pa-10px yellow accent-4"
  }, [_vm._v("accent-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px amber"
  }, [_vm._v("amber")]), _c('div', {
    staticClass: "pa-10px amber lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px amber lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px amber lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px amber lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px amber lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px amber darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px amber darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px amber darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px amber darken-4"
  }, [_vm._v("darken-4")]), _c('div', {
    staticClass: "pa-10px amber accent-1"
  }, [_vm._v("accent-1")]), _c('div', {
    staticClass: "pa-10px amber accent-2"
  }, [_vm._v("accent-2")]), _c('div', {
    staticClass: "pa-10px amber accent-3"
  }, [_vm._v("accent-3")]), _c('div', {
    staticClass: "pa-10px amber accent-4"
  }, [_vm._v("accent-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px orange"
  }, [_vm._v("orange")]), _c('div', {
    staticClass: "pa-10px orange lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px orange lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px orange lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px orange lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px orange lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px orange darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px orange darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px orange darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px orange darken-4"
  }, [_vm._v("darken-4")]), _c('div', {
    staticClass: "pa-10px orange accent-1"
  }, [_vm._v("accent-1")]), _c('div', {
    staticClass: "pa-10px orange accent-2"
  }, [_vm._v("accent-2")]), _c('div', {
    staticClass: "pa-10px orange accent-3"
  }, [_vm._v("accent-3")]), _c('div', {
    staticClass: "pa-10px orange accent-4"
  }, [_vm._v("accent-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px deep-orange"
  }, [_vm._v("deep-orange")]), _c('div', {
    staticClass: "pa-10px deep-orange lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px deep-orange lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px deep-orange lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px deep-orange lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px deep-orange lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px deep-orange darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px deep-orange darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px deep-orange darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px deep-orange darken-4"
  }, [_vm._v("darken-4")]), _c('div', {
    staticClass: "pa-10px deep-orange accent-1"
  }, [_vm._v("accent-1")]), _c('div', {
    staticClass: "pa-10px deep-orange accent-2"
  }, [_vm._v("accent-2")]), _c('div', {
    staticClass: "pa-10px deep-orange accent-3"
  }, [_vm._v("accent-3")]), _c('div', {
    staticClass: "pa-10px deep-orange accent-4"
  }, [_vm._v("accent-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px brown"
  }, [_vm._v("brown")]), _c('div', {
    staticClass: "pa-10px brown lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px brown lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px brown lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px brown lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px brown lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px brown darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px brown darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px brown darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px brown darken-4"
  }, [_vm._v("darken-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px blue-grey"
  }, [_vm._v("blue-grey")]), _c('div', {
    staticClass: "pa-10px blue-grey lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px blue-grey lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px blue-grey lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px blue-grey lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px blue-grey lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px blue-grey darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px blue-grey darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px blue-grey darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px blue-grey darken-4"
  }, [_vm._v("darken-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px grey"
  }, [_vm._v("grey")]), _c('div', {
    staticClass: "pa-10px grey lighten-5"
  }, [_vm._v("lighten-5")]), _c('div', {
    staticClass: "pa-10px grey lighten-4"
  }, [_vm._v("lighten-4")]), _c('div', {
    staticClass: "pa-10px grey lighten-3"
  }, [_vm._v("lighten-3")]), _c('div', {
    staticClass: "pa-10px grey lighten-2"
  }, [_vm._v("lighten-2")]), _c('div', {
    staticClass: "pa-10px grey lighten-1"
  }, [_vm._v("lighten-1")]), _c('div', {
    staticClass: "pa-10px grey darken-1"
  }, [_vm._v("darken-1")]), _c('div', {
    staticClass: "pa-10px grey darken-2"
  }, [_vm._v("darken-2")]), _c('div', {
    staticClass: "pa-10px grey darken-3"
  }, [_vm._v("darken-3")]), _c('div', {
    staticClass: "pa-10px grey darken-4"
  }, [_vm._v("darken-4")])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "pa-10px black"
  }, [_vm._v("black")]), _c('div', {
    staticClass: "pa-10px white"
  }, [_vm._v("white")]), _c('div', {
    staticClass: "pa-10px transparent"
  }, [_vm._v("transparent")])])], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v("text-color : "), _c('u', [_vm._v("{color}--text text--{lighten|darken|accent}-{i}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v("background-color : "), _c('u', [_vm._v("{color} {lighten|darken|accent}-{i}")])]);

}]

export { render, staticRenderFns }