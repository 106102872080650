<template>
    <u-card-gradient data-aos="fade-up" :data-aos-delay="$vuetify.breakpoint.mdAndUp ? '300' : '50'" v-bind="$attrs">
        <txt class="white--text txt--xl line-height-17 text-center">
            <template v-for="(_, slotName) in $slots" #[slotName]>
                <slot :name="slotName" />
            </template>
        </txt>  
    </u-card-gradient>
</template>

<script>
import UCardGradient from "@/components/publish/styles/cards/u-card-gradient.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        UCardGradient,
        Txt,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
