<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>chip-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <chip-primary>Chip</chip-primary>
            </v-col>
            <v-col cols="auto">
                <chip-secondary x-large>Chip</chip-secondary>
            </v-col>
            <v-col cols="auto">
                <chip-tertiary> Chip </chip-tertiary>
            </v-col>
            <v-col cols="auto">
                <chip-quarternary>
                    <u-icon class="v-icon--left">check</u-icon>
                    Chip
                </chip-quarternary>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Flat -->
        <h2>Flat</h2>
        <p><u>u-chip-flat-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-chip-flat-tile>Flat Tile</u-chip-flat-tile>
            </v-col>
            <v-col cols="auto">
                <u-chip-flat-label>Flat Label</u-chip-flat-label>
            </v-col>
            <v-col cols="auto">
                <u-chip-flat-pill>Flat Pill</u-chip-flat-pill>
            </v-col>
        </v-row>
        <!-- E: Flat -->
        <br />

        <!-- S: Outlined -->
        <h2>Outlined</h2>
        <p><u>u-chip-outlined-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-chip-outlined-tile>Outlined Tile</u-chip-outlined-tile>
            </v-col>
            <v-col cols="auto">
                <u-chip-outlined-label>Outlined Label</u-chip-outlined-label>
            </v-col>
            <v-col cols="auto">
                <u-chip-outlined-pill>Outlined Pill</u-chip-outlined-pill>
            </v-col>
        </v-row>
        <!-- E: Outlined -->
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Size -->
        <h1>Size</h1>
        <h3>{size} : x-small, small, (기본), large, x-large</h3>
        <p><u>{size}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-chip x-small>XSmall</v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip small>Small</v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip>Default</v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip large>Large</v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip x-large>XLarge</v-chip>
            </v-col>
        </v-row>
        <!-- // Size -->

        <v-divider class="my-30px" />

        <!-- Color -->
        <h1>Color</h1>
        <!-- S: Default -->
        <h2>Default</h2>
        <p><u>color="{color}"</u></p>
        <p><u>text-color="{color}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-chip color="primary">Primary</v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip color="secondary">Secondary</v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip color="accent">Accent</v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip color="grey" text-color="secondary lighten-5">Grey</v-chip>
            </v-col>
        </v-row>
        <!-- E: Default -->
        <br />

        <!-- S: Border -->
        <h2>Border</h2>
        <p><u>class="{color}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-chip outlined color="primary" class="secondary">Primary</v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip outlined color="secondary" class="accent">Secondary</v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip outlined color="accent" class="grey">Accent</v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip outlined color="grey" class="grey lighten-3">Grey</v-chip>
            </v-col>
        </v-row>
        <!-- E: Border -->
        <!-- // Color -->

        <v-divider class="my-30px" />

        <!-- State -->
        <h1>State</h1>
        <!-- S: Disabled -->
        <h2>Disabled</h2>
        <p><u>disabled</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-chip disabled color="primary">Default</v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip disabled color="primary">Flat</v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip disabled outlined color="primary">Outlined</v-chip>
            </v-col>
        </v-row>
        <!-- E: Disabled -->
        <br />

        <!-- S: Draggable -->
        <h2>Draggable</h2>
        <p><u>draggable</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-chip draggable color="primary">Draggable</v-chip>
            </v-col>
        </v-row>
        <!-- E: Draggable -->
        <br />

        <!-- S: Link -->
        <h2>Link</h2>
        <p><u>link</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-chip link color="primary">Default</v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip link color="primary">Flat</v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip link outlined color="primary">Outlined</v-chip>
            </v-col>
        </v-row>
        <!-- E: Link -->
        <!-- // State -->

        <v-divider class="my-30px" />

        <!-- Variant -->
        <h1>Variant</h1>
        <h3>{variant} : (기본-flat), outlined</h3>
        <p><u>{variant}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-chip>Default</v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip outlined>Outlined</v-chip>
            </v-col>
        </v-row>
        <!-- // Variant -->

        <v-divider class="my-30px" />

        <!-- Rounded -->
        <h1>Rounded</h1>
        <h3>{rounded} : (기본-pill), xs, sm, (md), lg, xl, 0</h3>
        <p>label은 입력방법이 다릅니다. 아래 예시를 참조해주세요.</p>
        <p><u>class="rounded-{rounded}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-chip color="primary">Default</v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip class="rounded-xs" color="primary">Rounded XS</v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip class="rounded-sm" color="primary">Rounded Sm</v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip class="rounded" color="primary">Rounded</v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip class="rounded-lg" color="primary">Rounded Lg</v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip class="rounded-xl" color="primary">Rounded XL</v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip class="rounded-0" color="primary">Tile</v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip label color="primary">Label</v-chip>
            </v-col>
        </v-row>
        <!-- // Rounded -->

        <v-divider class="my-30px" />

        <!-- Icon -->
        <h1>Icon</h1>
        <h3>{direction} : left, right</h3>
        <p>vue에서 렌더링 하는데 소모하는 시간이 있어 되도록이면 icon=""을 사용하지 않고 template v-slot으로 아이콘을 추가합니다.</p>
        <p>이벤트가 있는 아이콘은 template v-slot으로 사용하면 이벤트가 작동되지 않아 {direction}=""형태로 수정합니다. 이때 아이콘은 mdi아이콘만 사용할 수 있습니다.</p>
        <p><u>class="v-icon--{direction}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-chip color="primary">
                    <u-icon class="v-icon--left">favorite</u-icon>
                    Prepend Icon
                </v-chip>
            </v-col>
            <v-col cols="auto">
                <v-chip color="primary">
                    Append Icon
                    <u-icon class="v-icon--right">favorite</u-icon>
                </v-chip>
            </v-col>
        </v-row>
        <!-- // Icon -->
    </div>
</template>

<script>
import GuideApp from "./guide-app.vue";

import ChipPrimary from "@/components/publish/parents/chips/chip-primary.vue";
import ChipSecondary from "@/components/publish/parents/chips/chip-secondary.vue";
import ChipTertiary from "@/components/publish/parents/chips/chip-tertiary.vue";
import ChipQuarternary from "@/components/publish/parents/chips/chip-quarternary.vue";

import UChipFlatTile from "@/components/publish/styles/chips/u-chip-flat-tile.vue";
import UChipFlatLabel from "@/components/publish/styles/chips/u-chip-flat-label.vue";
import UChipFlatPill from "@/components/publish/styles/chips/u-chip-flat-pill.vue";
import UChipOutlinedTile from "@/components/publish/styles/chips/u-chip-outlined-tile.vue";
import UChipOutlinedLabel from "@/components/publish/styles/chips/u-chip-outlined-label.vue";
import UChipOutlinedPill from "@/components/publish/styles/chips/u-chip-outlined-pill.vue";

import UIcon from "@/components/publish/styles/icons/u-icon.vue";

export default {
    props: {},
    components: {
        GuideApp,

        ChipPrimary,
        ChipSecondary,
        ChipTertiary,
        ChipQuarternary,

        UChipFlatTile,
        UChipFlatLabel,
        UChipFlatPill,
        UChipOutlinedTile,
        UChipOutlinedLabel,
        UChipOutlinedPill,

        UIcon,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {
    },
};
</script>

<style lang="scss" scoped></style>
