<template>
    <u-btn-hamburger-concave class="btn-hamburger--light" @click="$emit('click')"><slot /></u-btn-hamburger-concave>
</template>

<script>
import UBtnHamburgerConcave from "@/components/publish/styles/buttons/u-btn-hamburger-concave.vue";

export default {
    props: {},
    components: {
        UBtnHamburgerConcave,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
