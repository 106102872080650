var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('div'), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _c('p', [_vm._v("top버튼은 보통 하나만 사용하므로 styles컴포넌트를 따로 만들지 않고 가이드에서 스타일을 복사해서 사용합니다.")]), _c('br'), _c('h2', [_vm._v("Tile")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-tile', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-tile', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-tile', {
    staticClass: "border-color border-grey-lighten-3",
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-tile', {
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-tile', {
    attrs: {
      "size": "large",
      "color": "white",
      "elevation": "4"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-tile', {
    staticClass: "border-2",
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-tile', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-tile', {
    staticClass: "border-color border-grey-lighten-3",
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-tile', {
    staticClass: "border-2",
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-tile', {
    attrs: {
      "size": "large",
      "color": "white",
      "elevation": "4"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-tile', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-tile', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-tile', {
    staticClass: "border-color border-grey-lighten-3",
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-tile', {
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-tile', {
    attrs: {
      "size": "large",
      "color": "white",
      "elevation": "4"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-tile', {
    staticClass: "border-2",
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('b', {
    staticClass: "font-size-14"
  }, [_vm._v("Top")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-tile', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v("Top")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-tile', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v("Top")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-tile', {
    staticClass: "border-color border-grey-lighten-3",
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v("Top")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-tile', {
    staticClass: "border-2 border-color border-grey-lighten-3",
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('b', {
    staticClass: "font-size-14"
  }, [_vm._v("Top")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-tile', {
    attrs: {
      "size": "large",
      "color": "white",
      "elevation": "4"
    }
  }, [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v("Top")])])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-tile', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "line-height-0"
  }, [_c('icon-chevron', {
    attrs: {
      "size": "xx-small",
      "direction": "up"
    }
  }), _c('div', {
    staticClass: "font-size-12 mt-2px line-height-1"
  }, [_vm._v("TOP")])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-tile', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "line-height-0"
  }, [_c('icon-chevron', {
    attrs: {
      "size": "xx-small",
      "direction": "up"
    }
  }), _c('div', {
    staticClass: "font-size-12 mt-2px line-height-1"
  }, [_vm._v("TOP")])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-tile', {
    staticClass: "border-color border-grey-lighten-3",
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('div', {
    staticClass: "line-height-0"
  }, [_c('icon-chevron', {
    attrs: {
      "size": "xx-small",
      "direction": "up"
    }
  }), _c('div', {
    staticClass: "font-size-12 mt-2px line-height-1"
  }, [_vm._v("TOP")])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-tile', {
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('div', {
    staticClass: "line-height-0"
  }, [_c('icon-chevron', {
    attrs: {
      "size": "xx-small",
      "direction": "up"
    }
  }), _c('div', {
    staticClass: "font-size-12 mt-2px line-height-1"
  }, [_vm._v("TOP")])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-tile', {
    attrs: {
      "size": "large",
      "color": "white",
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "line-height-0"
  }, [_c('icon-chevron', {
    attrs: {
      "size": "xx-small",
      "direction": "up"
    }
  }), _c('div', {
    staticClass: "font-size-12 mt-2px line-height-1"
  }, [_vm._v("TOP")])], 1)])], 1)], 1)], 1)], 1), _c('br'), _c('h2', [_vm._v("Rounded")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-rounded', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-rounded', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-rounded', {
    staticClass: "border-color border-grey-lighten-3",
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-rounded', {
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-rounded', {
    attrs: {
      "size": "large",
      "color": "white",
      "elevation": "4"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-rounded', {
    staticClass: "border-2",
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-rounded', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-rounded', {
    staticClass: "border-color border-grey-lighten-3",
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-rounded', {
    staticClass: "border-2",
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-rounded', {
    attrs: {
      "size": "large",
      "color": "white",
      "elevation": "4"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-rounded', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-rounded', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-rounded', {
    staticClass: "border-color border-grey-lighten-3",
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-rounded', {
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-rounded', {
    attrs: {
      "size": "large",
      "color": "white",
      "elevation": "4"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-rounded', {
    staticClass: "border-2",
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('b', {
    staticClass: "font-size-14"
  }, [_vm._v("Top")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-rounded', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v("Top")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-rounded', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v("Top")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-rounded', {
    staticClass: "border-color border-grey-lighten-3",
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v("Top")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-rounded', {
    staticClass: "border-2 border-color border-grey-lighten-3",
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('b', {
    staticClass: "font-size-14"
  }, [_vm._v("Top")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-rounded', {
    attrs: {
      "size": "large",
      "color": "white",
      "elevation": "4"
    }
  }, [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v("Top")])])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-rounded', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "line-height-0"
  }, [_c('icon-chevron', {
    attrs: {
      "size": "xx-small",
      "direction": "up"
    }
  }), _c('div', {
    staticClass: "font-size-12 mt-2px line-height-1"
  }, [_vm._v("TOP")])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-rounded', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "line-height-0"
  }, [_c('icon-chevron', {
    attrs: {
      "size": "xx-small",
      "direction": "up"
    }
  }), _c('div', {
    staticClass: "font-size-12 mt-2px line-height-1"
  }, [_vm._v("TOP")])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-rounded', {
    staticClass: "border-color border-grey-lighten-3",
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('div', {
    staticClass: "line-height-0"
  }, [_c('icon-chevron', {
    attrs: {
      "size": "xx-small",
      "direction": "up"
    }
  }), _c('div', {
    staticClass: "font-size-12 mt-2px line-height-1"
  }, [_vm._v("TOP")])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-rounded', {
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('div', {
    staticClass: "line-height-0"
  }, [_c('icon-chevron', {
    attrs: {
      "size": "xx-small",
      "direction": "up"
    }
  }), _c('div', {
    staticClass: "font-size-12 mt-2px line-height-1"
  }, [_vm._v("TOP")])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-rounded', {
    attrs: {
      "size": "large",
      "color": "white",
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "line-height-0"
  }, [_c('icon-chevron', {
    attrs: {
      "size": "xx-small",
      "direction": "up"
    }
  }), _c('div', {
    staticClass: "font-size-12 mt-2px line-height-1"
  }, [_vm._v("TOP")])], 1)])], 1)], 1)], 1)], 1), _c('br'), _c('h2', [_vm._v("Circle")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-circle', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-circle', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-circle', {
    staticClass: "border-color border-grey-lighten-3",
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-circle', {
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-circle', {
    attrs: {
      "size": "large",
      "color": "white",
      "elevation": "4"
    }
  }, [_c('icon-chevron', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-circle', {
    staticClass: "border-2",
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-circle', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-circle', {
    staticClass: "border-color border-grey-lighten-3",
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-circle', {
    staticClass: "border-2",
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-circle', {
    attrs: {
      "size": "large",
      "color": "white",
      "elevation": "4"
    }
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-circle', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-circle', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-circle', {
    staticClass: "border-color border-grey-lighten-3",
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-circle', {
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-circle', {
    attrs: {
      "size": "large",
      "color": "white",
      "elevation": "4"
    }
  }, [_c('icon-arrow-long', {
    attrs: {
      "size": "small",
      "direction": "up"
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-circle', {
    staticClass: "border-2",
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('b', {
    staticClass: "font-size-14"
  }, [_vm._v("Top")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-circle', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v("Top")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-circle', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v("Top")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-circle', {
    staticClass: "border-color border-grey-lighten-3",
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v("Top")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-circle', {
    staticClass: "border-2 border-color border-grey-lighten-3",
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('b', {
    staticClass: "font-size-14"
  }, [_vm._v("Top")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-circle', {
    attrs: {
      "size": "large",
      "color": "white",
      "elevation": "4"
    }
  }, [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v("Top")])])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-circle', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "line-height-0"
  }, [_c('icon-chevron', {
    attrs: {
      "size": "xx-small",
      "direction": "up"
    }
  }), _c('div', {
    staticClass: "font-size-12 mt-2px line-height-1"
  }, [_vm._v("TOP")])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-circle', {
    attrs: {
      "size": "large",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "line-height-0"
  }, [_c('icon-chevron', {
    attrs: {
      "size": "xx-small",
      "direction": "up"
    }
  }), _c('div', {
    staticClass: "font-size-12 mt-2px line-height-1"
  }, [_vm._v("TOP")])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-circle', {
    staticClass: "border-color border-grey-lighten-3",
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('div', {
    staticClass: "line-height-0"
  }, [_c('icon-chevron', {
    attrs: {
      "size": "xx-small",
      "direction": "up"
    }
  }), _c('div', {
    staticClass: "font-size-12 mt-2px line-height-1"
  }, [_vm._v("TOP")])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-circle', {
    attrs: {
      "size": "large",
      "color": "grey-darken-4"
    }
  }, [_c('div', {
    staticClass: "line-height-0"
  }, [_c('icon-chevron', {
    attrs: {
      "size": "xx-small",
      "direction": "up"
    }
  }), _c('div', {
    staticClass: "font-size-12 mt-2px line-height-1"
  }, [_vm._v("TOP")])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-circle', {
    attrs: {
      "size": "large",
      "color": "white",
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "line-height-0"
  }, [_c('icon-chevron', {
    attrs: {
      "size": "xx-small",
      "direction": "up"
    }
  }), _c('div', {
    staticClass: "font-size-12 mt-2px line-height-1"
  }, [_vm._v("TOP")])], 1)])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("btn-top-primary")])]);

}]

export { render, staticRenderFns }