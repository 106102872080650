var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-text-primary', {
    on: {
      "click": _vm.clickTest
    }
  }, [_vm._v("버튼")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-text-primary2', {
    on: {
      "click": _vm.clickTest
    }
  }, [_vm._v("버튼")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-text-primary3', {
    on: {
      "click": _vm.clickTest
    }
  }, [_vm._v("버튼")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-text-secondary', {
    on: {
      "click": _vm.clickTest
    }
  }, [_vm._v("버튼")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-text-secondary2', {
    on: {
      "click": _vm.clickTest
    }
  }, [_vm._v("버튼")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-text-secondary3', {
    on: {
      "click": _vm.clickTest
    }
  }, [_vm._v("버튼")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-text-tertiary', {
    on: {
      "click": _vm.clickTest
    }
  }, [_vm._v("버튼")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-text-tertiary2', {
    on: {
      "click": _vm.clickTest
    }
  }, [_vm._v("버튼")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-text-tertiary3', {
    on: {
      "click": _vm.clickTest
    }
  }, [_vm._v("버튼")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-text-quarternary', {
    on: {
      "click": _vm.clickTest
    }
  }, [_vm._v("버튼")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-text-quarternary2', {
    on: {
      "click": _vm.clickTest
    }
  }, [_vm._v("버튼")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-text-quarternary3', {
    on: {
      "click": _vm.clickTest
    }
  }, [_vm._v("버튼")])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _c('h2', [_vm._v("Text")]), _vm._m(1), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-text-tile', [_vm._v("Text Tile")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-text-rounded', [_vm._v("Text Rounded")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-text-pill', [_vm._v("Text Pill")])], 1)], 1), _c('br'), _c('h2', [_vm._v("Text Custom")]), _vm._m(2), _vm._m(3), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-text-fit', [_vm._v("Text Fit")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-text-underline', [_vm._v("Text Underline")])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Variant")]), _c('h3', [_vm._v("{variant} : text, text-fit, text-underline")]), _vm._m(4), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    }
  }, [_vm._v("Text")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-fit",
    attrs: {
      "text": "",
      "color": "primary"
    }
  }, [_vm._v("Text Fit")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-underline",
    attrs: {
      "text": "",
      "color": "primary"
    }
  }, [_vm._v("Text underline")])], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("btn-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-btn-text-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-btn-text-fit")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-btn-text-underline")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("{variant}")])]);

}]

export { render, staticRenderFns }