var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('i', _vm._b({
    staticClass: "v-icon",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'v-icon--size-' + _vm.size : '', _vm.variant ? 'material-icons-' + _vm.variant : 'material-icons']
  }, 'i', _vm.$attrs, false), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }