var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('tit-primary', [_vm._v("제목을입력해주세요")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('tit-secondary', [_vm._v(" 제목을입력해주세요 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('tit-tertiary', [_vm._v(" 제목을입력해주세요 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('tit-quarternary', [_vm._v(" 제목을입력해주세요 ")])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _c('h2', [_vm._v("Default")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('p', [_c('u', [_vm._v("u-tit-default")])]), _c('br'), _c('u-tit-default', [_vm._v("제목을입력해주세요")])], 1)], 1), _c('br'), _c('h2', [_vm._v("Sub title")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('p', [_c('u', [_vm._v("u-tit-subtit")])]), _c('br'), _c('u-tit-subtit', [_vm._v("소제목을입력해주세요")])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Size")]), _c('h3', [_vm._v("{size} : xxs, xs, sm, (기본), lg, xl, xxl")]), _vm._m(1), _c('br'), _c('tit', {
    staticClass: "tit--xxs"
  }, [_vm._v("제목을입력해주세요")]), _c('tit', {
    staticClass: "tit--xs"
  }, [_vm._v("제목을입력해주세요")]), _c('tit', {
    staticClass: "tit--sm"
  }, [_vm._v("제목을입력해주세요")]), _c('tit', [_vm._v("제목을입력해주세요")]), _c('tit', {
    staticClass: "tit--lg"
  }, [_vm._v("제목을입력해주세요")]), _c('tit', {
    staticClass: "tit--xl"
  }, [_vm._v("제목을입력해주세요")]), _c('tit', {
    staticClass: "tit--xxl"
  }, [_vm._v("제목을입력해주세요")])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("tit-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("tit--{size}")])]);

}]

export { render, staticRenderFns }