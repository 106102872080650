var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-window', _vm._b({
    on: {
      "change": _vm.TabsSecondaryValueChange
    },
    scopedSlots: _vm._u([_vm._l(_vm.$slots, function (_, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true),
    model: {
      value: _vm.TabsSecondaryValue,
      callback: function ($$v) {
        _vm.TabsSecondaryValue = $$v;
      },
      expression: "TabsSecondaryValue"
    }
  }, 'v-window', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }