<template>
    <v-tab v-bind="$attrs" class="v-tab--punch">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-tab>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.v-tab--punch {
    border: 1px solid transparent;
    border-bottom: 0;
    -webkit-transition: all ease-out 0.2s;
    -ms-transition: all ease-out 0.2s;
    transition: all ease-out 0.2s;
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        display: block;
        width: calc(100% + 2px);
        height: 1px;
        background-color: var(--v-grey-darken4);
        -webkit-transition: all ease-out 0.2s;
        -ms-transition: all ease-out 0.2s;
        transition: all ease-out 0.2s;
    }
    &.v-tab {
        &--active {
            font-weight: 400;
            color: var(--v-grey-darken4) !important;
            background-color: #fff !important;
            border-color: var(--v-grey-darken4) !important;
            &::after {
                background-color: inherit;
                opacity: 1;
            }
        }
    }
}
</style>
