<template>
    <v-window v-model="TabsQuarternaryValue" v-bind="$attrs" @change="TabsQuarternaryValueChange">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-window>
</template>

<script>
export default {
    props: {
        value: { type: [Number, String] },
    },
    components: {},
    data: () => {
        return {};
    },
    computed: {
        TabsQuarternaryValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("change", newValue);
            },
        },
    },
    mounted() {},
    methods: {
        TabsQuarternaryValueChange(newValue) {
            this.TabsQuarternaryValue = newValue;
        },
    },
};
</script>

<style lang="scss" scoped></style>
