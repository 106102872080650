var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.direction == 'up' || _vm.direction == 'down' ? _c('svg', _vm._b({
    staticClass: "icon-svg icon-arrow",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 13 16"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M0,6.4l1.14,1.12L5.69,3.04V16h1.62V3.04l4.55,4.48,1.14-1.12L6.5,0,0,6.4Z"
    }
  })]) : _c('svg', _vm._b({
    staticClass: "icon-svg icon-arrow",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 14"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M6.4 13.5L7.52 12.3625L3.04 7.8125L16 7.8125L16 6.1875L3.04 6.1875L7.52 1.6375L6.4 0.499999L5.68248e-07 7L6.4 13.5Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }