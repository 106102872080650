<template>
    <icon-arrow v-bind="$attrs" />
</template>

<script>
import IconArrow from "@/components/publish/styles/icons/icon-arrow.vue";
export default {
    props: {},
    components: {
        IconArrow,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
