<template>
    <v-chip v-bind="{ color, ...$attrs }" outlined class="rounded-0" @click="$emit('click')">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-chip>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "grey" },
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
