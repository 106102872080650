<template>
    <v-tab v-bind="$attrs" class="v-tab--slider">
        <span><slot /></span>
    </v-tab>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.v-tab--slider {
    background-color: transparent !important;
    > span {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        &::after {
            content: "";
            position: absolute;
            top: auto;
            right: auto;
            bottom: 0;
            left: 50%;
            -webkit-transform: translate(-50%, 0) scaleX(0);
            -ms-transform: translate(-50%, 0) scaleX(0);
            transform: translate(-50%, 0) scaleX(0);
            transform-origin: center;
            display: block;
            width: 100%;
            height: 2px;
            background-color: currentColor;
            -webkit-transition: all ease-out 0.2s;
            -ms-transition: all ease-out 0.2s;
            transition: all ease-out 0.2s;
        }
    }
    &.v-tab {
        &--active {
            color: currentColor !important;
            background-color: transparent !important;
            > span {
                &::after {
                    -webkit-transform: translate(-50%, 0) scaleX(1);
                    -ms-transform: translate(-50%, 0) scaleX(1);
                    transform: translate(-50%, 0) scaleX(1);
                }
            }
        }
    }
}
</style>
