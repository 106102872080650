<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>tabs-{parents}</u></p>
        <p><u>window-{parents}</u></p>
        <p><u>window-item-{parents}</u></p>
        <br />
        <!-- S: Tabs, Windows -->
        <h2>Tabs, Windows</h2>
        <v-row align="center">
            <v-col cols="12" md="6">
                <div class="tabs-wrap">
                    <tabs-primary v-model="TabsPrimary" @change="TabsPrimaryChange">
                        <tab-primary>Tab Primary1</tab-primary>
                        <tab-primary>Tab Primary2</tab-primary>
                        <tab-primary>Tab Primary3</tab-primary>
                    </tabs-primary>
                </div>
                <window-primary v-model="TabsPrimary" @change="TabsPrimaryChange">
                    <window-item-primary>Window Primary1</window-item-primary>
                    <window-item-primary>Window Primary2</window-item-primary>
                    <window-item-primary>Window Primary3</window-item-primary>
                </window-primary>
            </v-col>
            <v-col cols="12" md="6">
                <div class="tabs-wrap">
                    <tabs-secondary v-model="TabsSecondary" @change="TabsSecondaryChange">
                        <tab-secondary>Tab Secondary1</tab-secondary>
                        <tab-secondary>Tab Secondary2</tab-secondary>
                        <tab-secondary>Tab Secondary3</tab-secondary>
                    </tabs-secondary>
                </div>
                <window-secondary v-model="TabsSecondary" @change="TabsSecondaryChange">
                    <window-item-secondary>Window Secondary1</window-item-secondary>
                    <window-item-secondary>Window Secondary2</window-item-secondary>
                    <window-item-secondary>Window Secondary3</window-item-secondary>
                </window-secondary>
            </v-col>
            <v-col cols="12" md="6">
                <div class="tabs-wrap">
                    <tabs-tertiary v-model="TabsTertiary" @change="TabsTertiaryChange">
                        <tab-tertiary>Tab Tertiary1</tab-tertiary>
                        <tab-tertiary>Tab Tertiary2</tab-tertiary>
                        <tab-tertiary>Tab Tertiary3</tab-tertiary>
                    </tabs-tertiary>
                </div>
                <window-tertiary v-model="TabsTertiary" @change="TabsTertiaryChange">
                    <window-item-tertiary>Window Tertiary1</window-item-tertiary>
                    <window-item-tertiary>Window Tertiary2</window-item-tertiary>
                    <window-item-tertiary>Window Tertiary3</window-item-tertiary>
                </window-tertiary>
            </v-col>
            <v-col cols="12" md="6">
                <div class="tabs-wrap">
                    <tabs-quarternary v-model="TabsQuarternary" @change="TabsQuarternaryChange">
                        <tab-quarternary>Tab Quarternary1</tab-quarternary>
                        <tab-quarternary>Tab Quarternary2</tab-quarternary>
                        <tab-quarternary>Tab Quarternary3</tab-quarternary>
                    </tabs-quarternary>
                </div>
                <window-quarternary v-model="TabsQuarternary" @change="TabsQuarternaryChange">
                    <window-item-quarternary>Window Quarternary1</window-item-quarternary>
                    <window-item-quarternary>Window Quarternary2</window-item-quarternary>
                    <window-item-quarternary>Window Quarternary3</window-item-quarternary>
                </window-quarternary>
            </v-col>
        </v-row>
        <!-- E: Tabs, Windows -->
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Filled -->
        <h2>Filled</h2>
        <p><u>u-tabs-filled</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12">
                <u-tabs-filled>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                </u-tabs-filled>
            </v-col>
        </v-row>
        <!-- E: Filled -->
        <br />

        <!-- S: Border -->
        <h2>Border</h2>
        <p><u>u-tabs-border</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12">
                <u-tabs-border>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                </u-tabs-border>
            </v-col>
        </v-row>
        <!-- E: Border -->
        <br />

        <!-- S: Slider -->
        <h2>Slider</h2>
        <p><u>u-tabs-slider</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12">
                <u-tabs-slider>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                </u-tabs-slider>
            </v-col>
        </v-row>
        <!-- E: Slider -->
        <br />

        <!-- S: Dot -->
        <h2>Dot</h2>
        <p><u>u-tabs-dot</u></p>
        <p><u>u-tab-dot</u></p>
        <p>u-tabs-dot, u-tab-dot 세트로 사용합니다.</p>
        <br />
        <v-row align="center">
            <v-col cols="12">
                <u-tabs-dot>
                    <u-tab-dot>Tab</u-tab-dot>
                    <u-tab-dot>Tab</u-tab-dot>
                    <u-tab-dot>Tab</u-tab-dot>
                    <u-tab-dot>Tab</u-tab-dot>
                    <u-tab-dot>Tab</u-tab-dot>
                </u-tabs-dot>
            </v-col>
        </v-row>
        <!-- E: Dot -->
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Density -->
        <h1>Density</h1>
        <h3>{density} : compact, 기본-comfortable, default</h3>
        <p><u>class="v-tabs--density-{density}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12">
                <v-tabs v-bind="{ ...attrs_tabs_common }" :grow="false" class="v-tabs--density-compact">
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                </v-tabs>
            </v-col>
            <v-col cols="12">
                <v-tabs v-bind="{ ...attrs_tabs_common }" :grow="false">
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                </v-tabs>
            </v-col>
            <v-col cols="12">
                <v-tabs v-bind="{ ...attrs_tabs_common }" :grow="false" class="v-tabs--density-default">
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                </v-tabs>
            </v-col>
            <v-col cols="12">
                <v-tabs v-bind="{ ...attrs_tabs_common }" class="v-tabs--density-compact">
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                </v-tabs>
            </v-col>
            <v-col cols="12">
                <v-tabs v-bind="{ ...attrs_tabs_common }">
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                </v-tabs>
            </v-col>
            <v-col cols="12">
                <v-tabs v-bind="{ ...attrs_tabs_common }" class="v-tabs--density-default">
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <!-- // Density -->

        <v-divider class="my-30px" />

        <!-- Filled -->
        <h1>Filled</h1>
        <!-- S: Default -->
        <h2>Default</h2>
        <p><u>v-tabs--filled</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12">
                <v-tabs v-bind="{ ...attrs_tabs_common }" :grow="false" class="v-tabs--filled">
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                </v-tabs>
            </v-col>
            <v-col cols="12">
                <v-tabs v-bind="{ ...attrs_tabs_common }" class="v-tabs--filled">
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <!-- E: Default -->
        <br />

        <!-- S: Wide -->
        <h2>Wide</h2>
        <p><u>v-tabs--wide-filled</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12">
                <v-tabs v-bind="{ ...attrs_tabs_common }" :grow="false" class="v-tabs--wide-filled">
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                </v-tabs>
            </v-col>
            <v-col cols="12">
                <v-tabs v-bind="{ ...attrs_tabs_common }" class="v-tabs--wide-filled">
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <!-- E: Wide -->
        <!-- // Background -->

        <v-divider class="my-30px" />

        <!-- Border -->
        <h1>Border</h1>
        <!-- S: Default -->
        <h2>Default</h2>
        <p><u>v-tabs--border</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12">
                <v-tabs v-bind="{ ...attrs_tabs_common }" :grow="false" class="v-tabs--border">
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                </v-tabs>
            </v-col>
            <v-col cols="12">
                <v-tabs v-bind="{ ...attrs_tabs_common }" class="v-tabs--border">
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <!-- E: Default -->
        <br />

        <!-- S: Wide -->
        <h2>Wide</h2>
        <p><u>v-tabs--wide-border</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12">
                <v-tabs v-bind="{ ...attrs_tabs_common }" :grow="false" class="v-tabs--wide-border">
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                </v-tabs>
            </v-col>
            <v-col cols="12">
                <v-tabs v-bind="{ ...attrs_tabs_common }" class="v-tabs--wide-border">
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                    <v-tab>Tab</v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <!-- E: Wide -->
        <!-- // Border -->
    </div>
</template>

<script>
import { attrs_tabs_common } from "@/assets/variables";

import GuideApp from "./guide-app.vue";

import TabsPrimary from "@/components/publish/parents/tabs/tabs-primary.vue";
import TabsSecondary from "@/components/publish/parents/tabs/tabs-secondary.vue";
import TabsTertiary from "@/components/publish/parents/tabs/tabs-tertiary.vue";
import TabsQuarternary from "@/components/publish/parents/tabs/tabs-quarternary.vue";
import TabPrimary from "@/components/publish/parents/tabs/tab-primary.vue";
import TabSecondary from "@/components/publish/parents/tabs/tab-secondary.vue";
import TabTertiary from "@/components/publish/parents/tabs/tab-tertiary.vue";
import TabQuarternary from "@/components/publish/parents/tabs/tab-quarternary.vue";
import WindowPrimary from "@/components/publish/parents/windows/window-primary.vue";
import WindowSecondary from "@/components/publish/parents/windows/window-secondary.vue";
import WindowTertiary from "@/components/publish/parents/windows/window-tertiary.vue";
import WindowQuarternary from "@/components/publish/parents/windows/window-quarternary.vue";
import WindowItemPrimary from "@/components/publish/parents/windows/window-item-primary.vue";
import WindowItemSecondary from "@/components/publish/parents/windows/window-item-secondary.vue";
import WindowItemTertiary from "@/components/publish/parents/windows/window-item-tertiary.vue";
import WindowItemQuarternary from "@/components/publish/parents/windows/window-item-quarternary.vue";

import UTabsFilled from "@/components/publish/styles/tabs/u-tabs-filled.vue";
import UTabsBorder from "@/components/publish/styles/tabs/u-tabs-border.vue";
import UTabsSlider from "@/components/publish/styles/tabs/u-tabs-slider.vue";
import UTabsDot from "@/components/publish/styles/tabs/u-tabs-dot.vue";
import UTabDot from "@/components/publish/styles/tabs/u-tab-dot.vue";
export default {
    props: {},
    components: {
        GuideApp,

        TabsPrimary,
        TabsSecondary,
        TabsTertiary,
        TabsQuarternary,
        TabPrimary,
        TabSecondary,
        TabTertiary,
        TabQuarternary,
        WindowPrimary,
        WindowSecondary,
        WindowTertiary,
        WindowQuarternary,
        WindowItemPrimary,
        WindowItemSecondary,
        WindowItemTertiary,
        WindowItemQuarternary,

        UTabsFilled,
        UTabsBorder,
        UTabsSlider,
        UTabsDot,
        UTabDot,
    },
    data: () => {
        return {
            attrs_tabs_common,

            TabsPrimary: undefined,
            TabsSecondary: undefined,
            TabsTertiary: undefined,
            TabsQuarternary: undefined,
        };
    },
    mounted() {},
    methods: {
        TabsPrimaryChange(newValue) {
            this.TabsPrimary = newValue;
        },
        TabsSecondaryChange(newValue) {
            this.TabsSecondary = newValue;
        },
        TabsTertiaryChange(newValue) {
            this.TabsTertiary = newValue;
        },
        TabsQuarternaryChange(newValue) {
            this.TabsQuarternary = newValue;
        },
    },
};
</script>

<style lang="scss" scoped></style>
