<template>
    <u-txt-medium v-bind="$attrs" class="txt--light"><slot /></u-txt-medium>
</template>

<script>
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";

export default {
    props: {},
    components: {
        UTxtMedium,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
