var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [this.$slots['TitAdd'] ? _c('u-tit-subtit', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._t("TitAdd")], 2) : _vm._e(), _c('u-tit-default', {
    staticClass: "tit--xl",
    class: _vm.dark ? 'white--text' : '',
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._t("default")], 2), _c('span', {
    staticClass: "line line--lg mx-auto my-20px my-md-40px",
    class: !_vm.dark ? 'line--dark' : '',
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "50"
    }
  }), this.$slots['TxtAdd'] ? _c('u-txt-default', {
    staticClass: "txt--xl",
    class: _vm.dark ? 'white--text' : 'txt--dark',
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_vm._t("TxtAdd")], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }