<template>
    <div>
        <!-- Common -->
        <h1>공통</h1>
        <h3>{breakpoint} : sm<small>(min-width:576px)</small>, md<small>(min-width:768px)</small>, lg<small>(min-width:1024px)</small>, xl<small>(min-width:1200px)</small>, xxl<small>(min-width:1440px)</small></h3>
        <h3>{size} : 2px씩 증가</h3>
        <h3>{parents} : primary(2,3,4...), secondary, tertiary, quarternary</h3>
        <h3><small>같은 parents는 모양이 같습니다. (ex. btn-primary = btn-primary3) 색상이 추가되면 뒤에 숫자를 붙여서 생성 해주세요. 1에는 숫자를 붙이지 않습니다.</small></h3>
        <h3>{color} : vuetify 컬러시스템</h3>
        <!-- // Common -->

        <!-- <v-tabs color="grey" class="mt-60px mb-30px">
            <v-tab to="/guide/utilities">Utilities</v-tab>
            <v-tab to="/guide/colors">Colors</v-tab>
            <v-tab to="/guide/typography">Typography</v-tab>
            <v-tab to="/guide/buttons">Buttons</v-tab>
            <v-tab to="/guide/chips">Chips</v-tab>
            <v-tab to="/guide/icons">Icons</v-tab>
            <v-tab to="/guide/forms">Forms</v-tab>
            <v-tab to="/guide/tooltips">Tooltips</v-tab>
            <v-tab to="/guide/dividers">Dividers</v-tab>
            <v-tab to="/guide/scrolldowns">Scrolldowns</v-tab>
            <v-tab to="/guide/paginations">Paginations</v-tab>
            <v-tab to="/guide/ratings">Ratings</v-tab>
            <v-tab>Indicators</v-tab>
            <v-tab to="/guide/tabs">Tabs</v-tab>
            <v-tab to="/guide/tables">Tables</v-tab>
            <v-tab to="/guide/cards">Cards</v-tab>
            <v-tab to="/guide/menus">Menus</v-tab>
            <v-tab to="/guide/dialogs">Dialogs</v-tab>
            <v-tab to="/guide/headers">Headers</v-tab>
            <v-tab to="/guide/footers">Footers</v-tab>
        </v-tabs> -->
    </div>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
