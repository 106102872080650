<template>
    <u-tabs-filled v-model="TabsSecondaryValue" v-bind="$attrs" @change="TabsSecondaryValueChange">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-tabs-filled>
</template>

<script>
import UTabsFilled from "@/components/publish/styles/tabs/u-tabs-filled.vue";

export default {
    props: {
        value: { type: [Number, String] },
    },
    components: {
        UTabsFilled,
    },
    data() {
        return {
        };
    },
    computed: {
        TabsSecondaryValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("change", newValue);
            },
        },
    },
    mounted() {},
    methods: {
        TabsSecondaryValueChange(newValue) {
            this.TabsSecondaryValue = newValue;
        },
    },
};
</script>

<style lang="scss" scoped>
.v-tabs{
    background-color: var(--v-primary-base);
}
</style>

