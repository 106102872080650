<template>
    <client-page>
        <v-container>
            <guide-common />

            <v-tabs v-model="tab" class="mt-60px mb-30px">
                <v-tab>Utilities</v-tab>
                <v-tab>Colors</v-tab>
                <v-tab>Typography</v-tab>
                <v-tab>Buttons</v-tab>
                <v-tab>Chips</v-tab>
                <v-tab>Icons</v-tab>
                <!-- <v-tab>Forms</v-tab>
                <v-tab>Tooltips</v-tab>
                <v-tab>Dividers</v-tab>
                <v-tab>Scrolldowns</v-tab>
                <v-tab>Paginations</v-tab>
                <v-tab>Ratings</v-tab>
                <v-tab>Indicators</v-tab> -->
                <v-tab>Tabs</v-tab>
                <!-- <v-tab>Tables</v-tab> -->
                <v-tab>Cards</v-tab>
                <!-- <v-tab>Menus</v-tab>
                <v-tab>Dialogs</v-tab>
                <v-tab>Headers</v-tab>
                <v-tab>Footers</v-tab> -->
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item><utilities /></v-tab-item>
                <v-tab-item><colors /></v-tab-item>
                <v-tab-item><typography /></v-tab-item>
                <v-tab-item><buttons /></v-tab-item>
                <v-tab-item><chips /></v-tab-item>
                <v-tab-item><icons /></v-tab-item>
                <!-- <v-tab-item><forms /></v-tab-item>
                <v-tab-item><tooltips /></v-tab-item>
                <v-tab-item><dividers /></v-tab-item>
                <v-tab-item><scrolldowns /></v-tab-item>
                <v-tab-item><paginations /></v-tab-item>
                <v-tab-item><ratings /></v-tab-item>
                <v-tab-item><indicators /></v-tab-item> -->
                <v-tab-item><tabs /></v-tab-item>
                <!-- <v-tab-item><tables /></v-tab-item> -->
                <v-tab-item><cards /></v-tab-item>
                <!-- <v-tab-item><menus /></v-tab-item>
                <v-tab-item><dialogs /></v-tab-item>
                <v-tab-item><headers /></v-tab-item> -->
            </v-tabs-items>
        </v-container>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import GuideCommon from "./guide-common.vue";
import Utilities from "./utilities.vue";
import Colors from "./colors.vue";
import Typography from "./typography.vue";
import Buttons from "./buttons.vue";
import Chips from "./chips.vue";
import Icons from "./icons.vue";
// import Forms from "./forms.vue";
// import Tooltips from "./tooltips.vue";
// import Dividers from "./dividers.vue";
// import Scrolldowns from "./scrolldowns.vue";
// import Paginations from "./paginations.vue";
// import Ratings from "./ratings.vue";
// import Indicators from "./indicators.vue";
import Tabs from "./tabs.vue";
// import Tables from "./tables.vue";
import Cards from "./cards.vue";
// import Menus from "./menus.vue";
// import Dialogs from "./dialogs.vue";
// import Headers from "./headers.vue";

export default {
    components: {
        ClientPage,
        GuideCommon,
        Utilities,
        Colors,
        Typography,
        Buttons,
        Chips,
        Icons,
        // Forms,
        // Tooltips,
        // Dividers,
        // Scrolldowns,
        // Paginations,
        // Ratings,
        // Indicators,
        Tabs,
        // Tables,
        Cards,
        // Menus,
        // Dialogs,
        // Headers,
    },
    props: {},
    data() {
        return {
            tab: null,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.row {
    margin: -6px;
    [class*="col"] {
        padding: 6px;
    }
}
</style>
