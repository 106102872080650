<template>
    <div>
        <v-tabs v-model="tab" grow class="mb-30px">
            <v-tab>Common</v-tab>
            <v-tab>Default</v-tab>
            <v-tab>Text</v-tab>
            <v-tab>Icon</v-tab>
            <v-tab>Arrow</v-tab>
            <v-tab>Top</v-tab>
            <v-tab>Hamburger</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item><buttons-common /></v-tab-item>
            <v-tab-item><buttons-default /></v-tab-item>
            <v-tab-item><buttons-text /></v-tab-item>
            <v-tab-item><buttons-icon /></v-tab-item>
            <v-tab-item><buttons-arrow /></v-tab-item>
            <v-tab-item><buttons-top /></v-tab-item>
            <v-tab-item><buttons-hamburger /></v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import GuideApp from "./guide-app.vue";

import ButtonsCommon from "./buttons-common.vue";
import ButtonsDefault from "./buttons-default.vue";
import ButtonsText from "./buttons-text.vue";
import ButtonsIcon from "./buttons-icon.vue";
import ButtonsArrow from "./buttons-arrow.vue";
import ButtonsTop from "./buttons-top.vue";
import ButtonsHamburger from "./buttons-hamburger.vue";

export default {
    props: {},
    components: {
        GuideApp,

        ButtonsCommon,
        ButtonsDefault,
        ButtonsText,
        ButtonsIcon,
        ButtonsArrow,
        ButtonsTop,
        ButtonsHamburger,
    },
    data() {
        return {
            tab: null,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
