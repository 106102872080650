var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Spacing")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('h3', [_vm._v("{property}")]), _c('p', [_c('b', [_vm._v("m")]), _vm._v(" - applies margin"), _c('br'), _c('b', [_vm._v("p")]), _vm._v(" - applies padding ")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('h3', [_vm._v("{direction}")]), _c('p', [_c('b', [_vm._v("a")]), _vm._v(" - the property in all directions"), _c('br'), _c('b', [_vm._v("x")]), _vm._v(" - both *-left and *-right"), _c('br'), _c('b', [_vm._v("y")]), _vm._v(" - both *-top and *-bottom"), _c('br'), _c('b', [_vm._v("t")]), _vm._v(" - margin-top and padding-top"), _c('br'), _c('b', [_vm._v("b")]), _vm._v(" - margin-bottom and padding-bottom"), _c('br'), _c('b', [_vm._v("l")]), _vm._v(" - margin-left and padding-left"), _c('br'), _c('b', [_vm._v("r")]), _vm._v(" - margin-right and padding-right ")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('h3', [_vm._v("{size}")]), _c('p', [_vm._v("0, auto, 1px ~ 300px(px), n1px ~ n300px(-1px ~ -300px)")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_c('u', [_vm._v("{property}{direction}-{breakpoint}-{size}")])]), _c('p', [_vm._v("예시) pt-12px, pa-md-16px, ma-sm-10px, mt-m10px")])])], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Sizing")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('h3', [_vm._v("{abbrev}")]), _c('p', [_c('b', [_vm._v("w")]), _vm._v(" - width"), _c('br'), _c('b', [_vm._v("mw")]), _vm._v(" - max-width"), _c('br'), _c('b', [_vm._v("min-w")]), _vm._v(" - min-width"), _c('br'), _c('b', [_vm._v("h")]), _vm._v(" - height"), _c('br'), _c('b', [_vm._v("mh")]), _vm._v(" - max-height"), _c('br'), _c('b', [_vm._v("min-h")]), _vm._v(" - min-height"), _c('br'), _c('b', [_vm._v("vw")]), _vm._v(" - width"), _c('br'), _c('b', [_vm._v("vh")]), _vm._v(" - height"), _c('br'), _c('b', [_vm._v("min-vw")]), _vm._v(" - min-width"), _c('br'), _c('b', [_vm._v("min-vh")]), _vm._v(" - min-height"), _c('br')])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('h3', [_vm._v("{size}")]), _c('p', [_vm._v(" % - "), _c('b', [_vm._v("1 ~ 100")]), _vm._v("(10%씩 늘어납니다)"), _c('br'), _vm._v(" vw, vh - "), _c('b', [_vm._v("100")]), _vm._v("만 있습니다"), _c('br'), _vm._v(" px - "), _c('b', [_vm._v("10px ~ 300px")]), _vm._v("(10px씩 늘어납니다), auto ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_c('u', [_vm._v("{abbrev}-{breakpoint}-{size}")])]), _c('p', [_vm._v("예시) w-10px, mh-md-100px, w-100, min-vw-100, vh-100")])])], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Grid")]), _c('h2', [_vm._v("Size")]), _c('h3', [_vm._v("{size} : xxs, xs, sm, (기본), lg, xl, xxl")]), _vm._m(0), _c('p', [_vm._v("예시) row--sm, row--lg")]), _c('br'), _c('h2', [_vm._v("Row columns")]), _c('h3', [_vm._v("{i} : auto, 1 ~ 12")]), _vm._m(1), _c('p', [_vm._v("예시) row-cols-auto, row-cols-sm-5")]), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Text")]), _c('h2', [_vm._v("Size")]), _c('h3', [_vm._v("{size} : 10 ~ 120")]), _vm._m(2), _c('p', [_vm._v("예시) font-size-14, font-size-md-16")]), _c('br'), _c('h2', [_vm._v("Weight")]), _vm._m(3), _vm._m(4), _c('p', [_vm._v("예시) font-weight-light, font-weight-bold")]), _c('br'), _c('h2', [_vm._v("Word break")]), _c('h3', [_vm._v("{word-break} : normal, words, all, keep")]), _vm._m(5), _c('p', [_vm._v("예시) break-normal, break-all, break-keep")]), _c('br'), _c('h2', [_vm._v("Line height")]), _c('h3', [_vm._v("{height} : 0 ~ 2")]), _vm._m(6), _c('p', [_vm._v("예시) line-height-0, line-height-1, line-height-16, line-height-2")]), _c('br'), _c('h2', [_vm._v("Letter spacing")]), _c('h3', [_vm._v("{spacing} : n05 ~ 1")]), _vm._m(7), _c('p', [_vm._v("예시) letter-spacing-n05, letter-spacing-0, letter-spacing-04, letter-spacing-1")]), _c('br'), _c('h2', [_vm._v("말줄임")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h3', [_vm._v("한줄")]), _c('p', [_c('u', [_vm._v("text-truncate")])]), _c('p', {
    staticClass: "text-truncate"
  }, [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h3', [_vm._v("여러줄 / {line} : 2 ~ 10")]), _c('p', [_c('u', [_vm._v("text-truncate-{breakpoint}-{line}")])]), _c('p', {
    staticClass: "text-truncate-3"
  }, [_vm._v(" 동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세 남산 위에 저 소나무 철갑을 두른 듯 바람 서리 불변함은 우리 기상일세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세 가을 하늘 공활한데 높고 구름 없이 밝은 달은 우리 가슴 일편단심일세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세 이 기상과 이 맘으로 충성을 다하여 괴로우나 즐거우나 나라 사랑하세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세 ")])])], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Border")]), _c('h2', [_vm._v("방향")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('h3', [_vm._v("{direction}")]), _c('p', [_c('b', [_vm._v("t")]), _vm._v(" - border-top"), _c('br'), _c('b', [_vm._v("b")]), _vm._v(" - border-bottom"), _c('br'), _c('b', [_vm._v("l")]), _vm._v(" - border-left"), _c('br'), _c('b', [_vm._v("r")]), _vm._v(" - border-right"), _c('br'), _c('b', [_vm._v("x")]), _vm._v(" - both *-left and *-right"), _c('br'), _c('b', [_vm._v("y")]), _vm._v(" - both *-top and *-bottom"), _c('br')])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_c('u', [_vm._v("border-{breakpoint}-{direction}")])]), _c('p', [_vm._v("예시) border, border-sm-y, border-l")])])], 1), _c('br'), _c('h2', [_vm._v("Width")]), _c('h3', [_vm._v("{width} : 1 ~ 10")]), _vm._m(8), _c('p', [_vm._v("예시) border-0, border-md-2, border-8")]), _c('br'), _c('h2', [_vm._v("Color")]), _vm._m(9), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Layout")]), _c('h2', [_vm._v("Clear")]), _c('h3', [_vm._v("{clear} : both, fix")]), _vm._m(10), _c('p', [_vm._v("예시) clear-both, clear-fix")]), _c('br'), _c('h2', [_vm._v("z-index")]), _c('h3', [_vm._v("{index} : auto, 0, 1, 2, 10~50, n1, n2 n10~n50(10씩 증가)")]), _vm._m(11), _c('p', [_vm._v("예시) z-auto, z-n30, z-md-0 z-10")]), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Effects")]), _c('h2', [_vm._v("Opacity")]), _c('h3', [_vm._v("{opacity} : 0, 5, 10 ~ 100(10씩 증가)")]), _vm._m(12), _c('p', [_vm._v("예시) opacity-0, opacity-5, opacity-10, opacity-20")]), _c('br'), _c('h2', [_vm._v("Elevation")]), _c('h3', [_vm._v("{elevation} : 0 ~ 24")]), _vm._m(13), _c('p', [_vm._v("예시) elevation-0, elevation-5, elevation-10, elevation-20")]), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Interactivity")]), _c('h2', [_vm._v("Cursor")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', [_vm._v("{cursor} :")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "cursor-auto"
  }, [_vm._v("auto")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "cursor-none"
  }, [_vm._v("none")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "cursor-default"
  }, [_vm._v("default")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "cursor-pointer"
  }, [_vm._v("pointer")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "cursor-wait"
  }, [_vm._v("wait")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "cursor-text"
  }, [_vm._v("text")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "cursor-move"
  }, [_vm._v("move")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "cursor-no-drop"
  }, [_vm._v("no-drop")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "cursor-zoom-in"
  }, [_vm._v("zoom-in")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "cursor-zoom-out"
  }, [_vm._v("zoom-out")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_c('u', [_vm._v("cursor-{cursor}")])]), _c('p', [_vm._v("예시) cursor-auto, cursor-default, cursor-pointer")])])], 1), _c('br'), _c('h2', [_vm._v("Pointer events")]), _c('h3', [_vm._v("{pointer-events} : auto, none")]), _vm._m(14), _c('p', [_vm._v("예시) pointer-events-auto, pointer-events-none")]), _c('br'), _c('h2', [_vm._v("Resize")]), _c('h3', [_vm._v("{resize} : 공백, none, x, y")]), _vm._m(15), _c('p', [_vm._v("예시) resize, resize-none, resize-x, resize-y")])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("row--{size}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("row-cols-{breakpoint}-{i}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("font-size-{breakpoint}-{size}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', [_vm._v(" {weight} : "), _c('span', {
    staticClass: "font-weight-thin"
  }, [_vm._v("thin   ")]), _c('span', {
    staticClass: "font-weight-extralight"
  }, [_vm._v("extralight   ")]), _c('span', {
    staticClass: "font-weight-light"
  }, [_vm._v("light   ")]), _c('span', {
    staticClass: "font-weight-regular"
  }, [_vm._v("regular   ")]), _c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v("medium   ")]), _c('span', {
    staticClass: "font-weight-semibold"
  }, [_vm._v("semibold   ")]), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("bold   ")]), _c('span', {
    staticClass: "font-weight-extrabold"
  }, [_vm._v("extrabold   ")]), _c('span', {
    staticClass: "font-weight-black"
  }, [_vm._v("black   ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("font-weight-{weight}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("break-{word-break}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("line-height-{breakpoint}-{height}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("letter-spacing-{spacing}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("border-{breakpoint}-{width}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("border-{light|dark}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("clear-{clear}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("z-{breakpoint}-{z-index}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("opacity-{opacity}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("elevation-{elevation}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("pointer-events-{pointer-events}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("resize-{resize}")])]);

}]

export { render, staticRenderFns }