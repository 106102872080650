<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>btn-arrow-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <btn-arrow-primary />
                <btn-arrow-primary direction="right" />
            </v-col>
            <v-col cols="auto">
                <btn-arrow-secondary x-large />
                <btn-arrow-secondary x-large direction="right" />
            </v-col>
            <v-col cols="auto">
                <btn-arrow-tertiary color="primary" />
                <btn-arrow-tertiary color="primary" direction="right" />
            </v-col>
            <v-col cols="auto">
                <btn-arrow-quarternary color="secondary" />
                <btn-arrow-quarternary color="secondary" direction="right" />
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <p><u>v-btn--arrow</u></p>
        <br />
        <!-- S: Outlined -->
        <h2>Outlined</h2>
        <p><u>u-btn-arrow-outlined-{}</u></p>
        <br />
        <!-- S: icon-chevron -->
        <h3>icon-chevron</h3>
        <v-row align="center">
            <v-col cols="auto">
                <u-btn-arrow-outlined-tile class="grey lighten-3"><icon-chevron /></u-btn-arrow-outlined-tile>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-rounded class="grey lighten-3"><icon-chevron /></u-btn-arrow-outlined-rounded>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-circle class="grey lighten-3"><icon-chevron /></u-btn-arrow-outlined-circle>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-tile color="primary"><icon-chevron /></u-btn-arrow-outlined-tile>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-rounded color="primary"><icon-chevron /></u-btn-arrow-outlined-rounded>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-circle color="primary"><icon-chevron /></u-btn-arrow-outlined-circle>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-tile color="primary" class="v-btn--arrow grey lighten-3"><icon-chevron /></u-btn-arrow-outlined-tile>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-rounded color="primary" class="v-btn--arrow grey lighten-3"><icon-chevron /></u-btn-arrow-outlined-rounded>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-circle color="primary" class="v-btn--arrow grey lighten-3"><icon-chevron /></u-btn-arrow-outlined-circle>
            </v-col>
        </v-row>
        <!-- E: icon-chevron -->
        <br />

        <!-- S: icon-arrow -->
        <h3>icon-arrow</h3>
        <v-row align="center">
            <v-col cols="auto">
                <u-btn-arrow-outlined-tile class="grey lighten-3"><icon-arrow /></u-btn-arrow-outlined-tile>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-rounded class="grey lighten-3"><icon-arrow /></u-btn-arrow-outlined-rounded>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-circle class="grey lighten-3"><icon-arrow /></u-btn-arrow-outlined-circle>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-tile color="primary"><icon-arrow /></u-btn-arrow-outlined-tile>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-rounded color="primary"><icon-arrow /></u-btn-arrow-outlined-rounded>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-circle color="primary"><icon-arrow /></u-btn-arrow-outlined-circle>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-tile color="primary" class="v-btn--arrow grey lighten-3"><icon-arrow /></u-btn-arrow-outlined-tile>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-rounded color="primary" class="v-btn--arrow grey lighten-3"><icon-arrow /></u-btn-arrow-outlined-rounded>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-circle color="primary" class="v-btn--arrow grey lighten-3"><icon-arrow /></u-btn-arrow-outlined-circle>
            </v-col>
        </v-row>
        <!-- E: icon-arrow -->
        <br />

        <!-- S: icon-arrow-rounded -->
        <h3>icon-arrow-rounded</h3>
        <v-row align="center">
            <v-col cols="auto">
                <u-btn-arrow-outlined-tile class="grey lighten-3"><icon-arrow-rounded /></u-btn-arrow-outlined-tile>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-rounded class="grey lighten-3"><icon-arrow-rounded /></u-btn-arrow-outlined-rounded>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-circle class="grey lighten-3"><icon-arrow-rounded /></u-btn-arrow-outlined-circle>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-tile color="primary"><icon-arrow-rounded /></u-btn-arrow-outlined-tile>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-rounded color="primary"><icon-arrow-rounded /></u-btn-arrow-outlined-rounded>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-circle color="primary"><icon-arrow-rounded /></u-btn-arrow-outlined-circle>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-tile color="primary" class="v-btn--arrow grey lighten-3"><icon-arrow-rounded /></u-btn-arrow-outlined-tile>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-rounded color="primary" class="v-btn--arrow grey lighten-3"><icon-arrow-rounded /></u-btn-arrow-outlined-rounded>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-circle color="primary" class="v-btn--arrow grey lighten-3"><icon-arrow-rounded /></u-btn-arrow-outlined-circle>
            </v-col>
        </v-row>
        <!-- E: icon-arrow-rounded -->
        <br />

        <!-- S: icon-arrow-long -->
        <h3>icon-arrow-long</h3>
        <v-row align="center">
            <v-col cols="auto">
                <u-btn-arrow-outlined-tile class="grey lighten-3"><icon-arrow-long /></u-btn-arrow-outlined-tile>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-rounded class="grey lighten-3"><icon-arrow-long /></u-btn-arrow-outlined-rounded>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-circle class="grey lighten-3"><icon-arrow-long /></u-btn-arrow-outlined-circle>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-tile color="primary"><icon-arrow-long /></u-btn-arrow-outlined-tile>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-rounded color="primary"><icon-arrow-long /></u-btn-arrow-outlined-rounded>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-circle color="primary"><icon-arrow-long /></u-btn-arrow-outlined-circle>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-tile color="primary" class="v-btn--arrow grey lighten-3"><icon-arrow-long /></u-btn-arrow-outlined-tile>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-rounded color="primary" class="v-btn--arrow grey lighten-3"><icon-arrow-long /></u-btn-arrow-outlined-rounded>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-circle color="primary" class="v-btn--arrow grey lighten-3"><icon-arrow-long /></u-btn-arrow-outlined-circle>
            </v-col>
        </v-row>
        <!-- E: icon-arrow-long -->
        <br />

        <!-- S: icon-arrow-long-rounded -->
        <h3>icon-arrow-long-rounded</h3>
        <v-row align="center">
            <v-col cols="auto">
                <u-btn-arrow-outlined-tile class="grey lighten-3"><icon-arrow-long-rounded /></u-btn-arrow-outlined-tile>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-rounded class="grey lighten-3"><icon-arrow-long-rounded /></u-btn-arrow-outlined-rounded>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-circle class="grey lighten-3"><icon-arrow-long-rounded /></u-btn-arrow-outlined-circle>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-tile color="primary"><icon-arrow-long-rounded /></u-btn-arrow-outlined-tile>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-rounded color="primary"><icon-arrow-long-rounded /></u-btn-arrow-outlined-rounded>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-circle color="primary"><icon-arrow-long-rounded /></u-btn-arrow-outlined-circle>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-tile color="primary" class="v-btn--arrow grey lighten-3"><icon-arrow-long-rounded /></u-btn-arrow-outlined-tile>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-rounded color="primary" class="v-btn--arrow grey lighten-3"><icon-arrow-long-rounded /></u-btn-arrow-outlined-rounded>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-outlined-circle color="primary" class="v-btn--arrow grey lighten-3"><icon-arrow-long-rounded /></u-btn-arrow-outlined-circle>
            </v-col>
        </v-row>
        <!-- E: icon-arrow-long-rounded -->
        <!-- E: Outlined -->
        <br/>

        <!-- S: Text Fit -->
        <h2>Text Fit</h2>
        <p><u>u-btn-arrow-text-fit</u></p>
        <br />
        <!-- S: icon-chevron -->
        <h3>icon-chevron</h3>
        <v-row align="center">
            <v-col cols="auto">
                <u-btn-arrow-text-fit><icon-chevron /></u-btn-arrow-text-fit>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-text-fit color="primary"><icon-chevron /></u-btn-arrow-text-fit>
            </v-col>
        </v-row>
        <!-- E: icon-chevron -->
        <br />

        <!-- S: icon-arrow -->
        <h3>icon-arrow</h3>
        <v-row align="center">
            <v-col cols="auto">
                <u-btn-arrow-text-fit><icon-arrow /></u-btn-arrow-text-fit>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-text-fit color="primary"><icon-arrow /></u-btn-arrow-text-fit>
            </v-col>
        </v-row>
        <!-- E: icon-arrow -->
        <br />

        <!-- S: icon-arrow-rounded -->
        <h3>icon-arrow-rounded</h3>
        <v-row align="center">
            <v-col cols="auto">
                <u-btn-arrow-text-fit><icon-arrow-rounded /></u-btn-arrow-text-fit>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-text-fit color="primary"><icon-arrow-rounded /></u-btn-arrow-text-fit>
            </v-col>
        </v-row>
        <!-- E: icon-arrow-rounded -->
        <br />

        <!-- S: icon-arrow-long -->
        <h3>icon-arrow-long</h3>
        <v-row align="center">
            <v-col cols="auto">
                <u-btn-arrow-text-fit><icon-arrow-long /></u-btn-arrow-text-fit>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-text-fit color="primary"><icon-arrow-long /></u-btn-arrow-text-fit>
            </v-col>
        </v-row>
        <!-- E: icon-arrow-long -->
        <br />

        <!-- S: icon-arrow-long-rounded -->
        <h3>icon-arrow-long-rounded</h3>
        <v-row align="center">
            <v-col cols="auto">
                <u-btn-arrow-text-fit><icon-arrow-long-rounded /></u-btn-arrow-text-fit>
            </v-col>
            <v-col cols="auto">
                <u-btn-arrow-text-fit color="primary"><icon-arrow-long-rounded /></u-btn-arrow-text-fit>
            </v-col>
        </v-row>
        <!-- E: icon-arrow-long-rounded -->
        <!-- E: Text Fit -->
        <!-- // Styles -->
    </div>
</template>

<script>
import BtnArrowPrimary from "@/components/publish/parents/buttons/btn-arrow-primary.vue";
import BtnArrowSecondary from "@/components/publish/parents/buttons/btn-arrow-secondary.vue";
import BtnArrowTertiary from "@/components/publish/parents/buttons/btn-arrow-tertiary.vue";
import BtnArrowQuarternary from "@/components/publish/parents/buttons/btn-arrow-quarternary.vue";

import UBtnArrowOutlinedTile from "@/components/publish/styles/buttons/u-btn-arrow-outlined-tile.vue";
import UBtnArrowOutlinedRounded from "@/components/publish/styles/buttons/u-btn-arrow-outlined-rounded.vue";
import UBtnArrowOutlinedCircle from "@/components/publish/styles/buttons/u-btn-arrow-outlined-circle.vue";
import UBtnArrowTextFit from "@/components/publish/styles/buttons/u-btn-arrow-text-fit.vue";

import UIcon from "@/components/publish/styles/icons/u-icon.vue";
import IconChevron from "@/components/publish/styles/icons/icon-chevron.vue";
import IconArrow from "@/components/publish/styles/icons/icon-arrow.vue";
import IconArrowRounded from "@/components/publish/styles/icons/icon-arrow-rounded.vue";
import IconArrowLong from "@/components/publish/styles/icons/icon-arrow-long.vue";
import IconArrowLongRounded from "@/components/publish/styles/icons/icon-arrow-long-rounded.vue";

export default {
    props: {},
    components: {
        BtnArrowPrimary,
        BtnArrowSecondary,
        BtnArrowTertiary,
        BtnArrowQuarternary,

        UBtnArrowOutlinedTile,
        UBtnArrowOutlinedRounded,
        UBtnArrowOutlinedCircle,
        UBtnArrowTextFit,

        UIcon,
        IconChevron,
        IconArrow,
        IconArrowRounded,
        IconArrowLong,
        IconArrowLongRounded,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
