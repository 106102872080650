var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-icon-primary', {
    attrs: {
      "color": "secondary"
    }
  }, [_c('u-icon', [_vm._v("favorite")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-icon-secondary', {
    attrs: {
      "x-large": ""
    }
  }, [_c('u-icon', [_vm._v("favorite")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-icon-tertiary', {
    attrs: {
      "color": "primary"
    }
  }, [_c('u-icon', [_vm._v("favorite")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-icon-quarternary', {
    attrs: {
      "x-small": ""
    }
  }, [_c('u-icon', [_vm._v("favorite")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _c('h2', [_vm._v("Flat(Fab)")]), _vm._m(1), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-tile', [_c('u-icon', [_vm._v("favorite")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-rounded', [_c('u-icon', [_vm._v("favorite")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-flat-circle', [_c('u-icon', [_vm._v("favorite")])], 1)], 1)], 1), _c('br'), _c('h2', [_vm._v("Outlined")]), _vm._m(2), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-tile', [_c('u-icon', [_vm._v("favorite")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-rounded', [_c('u-icon', [_vm._v("favorite")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-icon-outlined-circle', [_c('u-icon', [_vm._v("favorite")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Variant")]), _c('h3', [_vm._v("{variant} : (기본-icon), fab, elevation, outlined, text, text-fit, text-underline")]), _vm._m(3), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "color": "primary"
    }
  }, [_c('u-icon', [_vm._v("favorite")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "fab": "",
      "color": "primary"
    }
  }, [_c('u-icon', [_vm._v("favorite")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "elevation": "10",
      "color": "primary"
    }
  }, [_c('u-icon', [_vm._v("favorite")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_c('u-icon', [_vm._v("favorite")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "text": "",
      "color": "primary"
    }
  }, [_c('u-icon', [_vm._v("favorite")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-fit",
    attrs: {
      "icon": "",
      "text": "",
      "color": "primary"
    }
  }, [_c('u-icon', [_vm._v("favorite")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-underline",
    attrs: {
      "icon": "",
      "text": "",
      "color": "primary"
    }
  }, [_c('u-icon', [_vm._v("favorite")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("btn-icon-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-btn-icon-flat-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-btn-icon-outlined-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("{variant}")])]);

}]

export { render, staticRenderFns }