<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <!-- S: Arrow -->
        <h2>Arrow</h2>
        <p><u>icon-arrow-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <icon-arrow-primary />
            </v-col>
            <v-col cols="auto">
                <icon-arrow-secondary direction="right" />
            </v-col>
            <v-col cols="auto">
                <icon-arrow-tertiary direction="left" />
            </v-col>
            <v-col cols="auto">
                <icon-arrow-quarternary direction="right" />
            </v-col>
        </v-row>
        <!-- E: Arrow -->
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Color -->
        <h1>Color</h1>
        <p><u>color="{color}"</u></p>
        <br />
        <v-row>
            <v-col cols="auto">
                <u-icon color="primary--text">shopping_cart</u-icon>
            </v-col>
            <v-col cols="auto">
                <u-icon color="secondary--text">shopping_cart</u-icon>
            </v-col>
            <v-col cols="auto">
                <u-icon color="grey--text">shopping_cart</u-icon>
            </v-col>
            <v-col cols="auto">
                <icon-chevron color="yellow--text" />
            </v-col>
            <v-col cols="auto">
                <icon-arrow color="teal--text" />
            </v-col>
            <v-col cols="auto">
                <icon-arrow-long-rounded color="light-blue--text" />
            </v-col>
        </v-row>
        <!-- // Color -->

        <v-divider class="my-30px" />

        <!-- Vuetify Icons -->
        <h1>Vuetify Icons</h1>
        <!-- S: u-icon -->
        <h2>u-icon</h2>
        <p>vue에서 렌더링 하는데 소모하는 시간이 있어 v-icon을 사용하지 않고 i 태그를 이용합니다.</p>
        <p>Material Icons 아이콘은 되도록이면 u-icon를 이용해 주세요.</p>
        <h3>{icon} : <a target="_blank" href="https://fonts.google.com/icons?icon.style=Filled&icon.set=Material+Icons">Material Icons</a></h3>
        <p><u>&lt;u-icon&gt;{icon}&lt;/u-icon&gt;</u></p>
        <!-- E: u-icon -->
        <br />

        <!-- S: Variant -->
        <h2>Variant</h2>
        <h3>{variant} : (기본-filled), outlined, round, sharp, two-tone</h3>
        <p><u>variant="{variant}"</u></p>
        <br />
        <v-row>
            <v-col cols="auto">
                <u-icon>shopping_cart</u-icon>
            </v-col>
            <v-col cols="auto">
                <u-icon variant="outlined">shopping_cart</u-icon>
            </v-col>
            <v-col cols="auto">
                <u-icon variant="round">shopping_cart</u-icon>
            </v-col>
            <v-col cols="auto">
                <u-icon variant="sharp">shopping_cart</u-icon>
            </v-col>
            <v-col cols="auto">
                <u-icon variant="two-tone">shopping_cart</u-icon>
            </v-col>
        </v-row>
        <!-- E: Variant -->
        <br />

        <!-- S: Size -->
        <h2>Size</h2>
        <h3>{size} : xx-small, x-small, small, (default), large, x-large, xx-large</h3>
        <p><u>size="{size}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-icon size="xx-small">shopping_cart</u-icon>
            </v-col>
            <v-col cols="auto">
                <u-icon size="x-small">shopping_cart</u-icon>
            </v-col>
            <v-col cols="auto">
                <u-icon size="small">shopping_cart</u-icon>
            </v-col>
            <v-col cols="auto">
                <u-icon>shopping_cart</u-icon>
            </v-col>
            <v-col cols="auto">
                <u-icon size="large">shopping_cart</u-icon>
            </v-col>
            <v-col cols="auto">
                <u-icon size="x-large">shopping_cart</u-icon>
            </v-col>
            <v-col cols="auto">
                <u-icon size="xx-large">shopping_cart</u-icon>
            </v-col>
        </v-row>
        <!-- E: Size -->
        <!-- // Vuetify Icons -->

        <v-divider class="my-30px" />

        <!-- Custom Icons -->
        <h1>Custom Icons</h1>
        <!-- S: Arrow -->
        <h2>Arrow</h2>
        <v-row>
            <v-col cols="12" md="6">
                <h3>{size} : xx-small, x-small, small, (default), large, x-large, xx-large</h3>
                <p><u>size="{size}"</u></p>
            </v-col>
            <v-col cols="12" md="6">
                <h3>{direction} : (left), right, up, down</h3>
                <p><u>direction="{direction}"</u></p>
            </v-col>
        </v-row>
        <br />

        <!-- S: icon-chevron -->
        <h3>icon-chevron</h3>
        <br />
        <v-row>
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <icon-chevron size="xx-small" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-chevron size="x-small" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-chevron size="small" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-chevron />
                    </v-col>
                    <v-col cols="auto">
                        <icon-chevron size="large" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-chevron size="x-large" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-chevron size="xx-large" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <icon-chevron />
                    </v-col>
                    <v-col cols="auto">
                        <icon-chevron direction="right" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-chevron direction="up" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-chevron direction="down" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- E: icon-chevron -->
        <br />

        <!-- S: icon-arrow -->
        <h3>icon-arrow</h3>
        <br />
        <v-row>
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <icon-arrow size="xx-small" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow size="x-small" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow size="small" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow size="large" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow size="x-large" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow size="xx-large" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <icon-arrow />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow direction="right" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow direction="up" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow direction="down" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- E: icon-arrow -->
        <br />

        <!-- S: icon-arrow-rounded -->
        <h3>icon-arrow-rounded</h3>
        <br />
        <v-row>
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <icon-arrow-rounded size="xx-small" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-rounded size="x-small" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-rounded size="small" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-rounded />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-rounded size="large" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-rounded size="x-large" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-rounded size="xx-large" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <icon-arrow-rounded />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-rounded direction="right" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-rounded direction="up" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-rounded direction="down" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- E: icon-arrow-rounded -->
        <br />

        <!-- S: icon-arrow-long -->
        <h3>icon-arrow-long</h3>
        <br />
        <v-row>
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <icon-arrow-long size="xx-small" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-long size="x-small" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-long size="small" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-long />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-long size="large" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-long size="x-large" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-long size="xx-large" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <icon-arrow-long />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-long direction="right" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-long direction="up" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-long direction="down" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- E: icon-arrow-long -->
        <br />

        <!-- S: icon-arrow-long-rounded -->
        <h3>icon-arrow-long-rounded</h3>
        <br />
        <v-row>
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <icon-arrow-long-rounded size="xx-small" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-long-rounded size="x-small" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-long-rounded size="small" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-long-rounded />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-long-rounded size="large" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-long-rounded size="x-large" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-long-rounded size="xx-large" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <icon-arrow-long-rounded />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-long-rounded direction="right" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-long-rounded direction="up" />
                    </v-col>
                    <v-col cols="auto">
                        <icon-arrow-long-rounded direction="down" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- E: icon-arrow-long-rounded -->
        <!-- E: Arrow -->

        <!-- // Custom Icons -->
    </div>
</template>

<script>
import GuideApp from "./guide-app.vue";

import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";
import IconArrowSecondary from "@/components/publish/parents/icons/icon-arrow-secondary.vue";
import IconArrowTertiary from "@/components/publish/parents/icons/icon-arrow-tertiary.vue";
import IconArrowQuarternary from "@/components/publish/parents/icons/icon-arrow-quarternary.vue";

import UIcon from "@/components/publish/styles/icons/u-icon.vue";
import IconChevron from "@/components/publish/styles/icons/icon-chevron.vue";
import IconArrow from "@/components/publish/styles/icons/icon-arrow.vue";
import IconArrowRounded from "@/components/publish/styles/icons/icon-arrow-rounded.vue";
import IconArrowLong from "@/components/publish/styles/icons/icon-arrow-long.vue";
import IconArrowLongRounded from "@/components/publish/styles/icons/icon-arrow-long-rounded.vue";

export default {
    props: {},
    components: {
        GuideApp,

        IconArrowPrimary,
        IconArrowSecondary,
        IconArrowTertiary,
        IconArrowQuarternary,

        UIcon,
        IconChevron,
        IconArrow,
        IconArrowRounded,
        IconArrowLong,
        IconArrowLongRounded,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
