<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>tab-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12" md="6">
                <v-tabs v-bind="{ ...attrs_tabs_common, ...$attrs }">
                    <tab-primary>Tab Primary1</tab-primary>
                    <tab-primary>Tab Primary2</tab-primary>
                </v-tabs>
            </v-col>
            <v-col cols="12" md="6">
                <v-tabs v-bind="{ ...attrs_tabs_common, ...$attrs }">
                    <tab-secondary>Tab Secondary1</tab-secondary>
                    <tab-secondary>Tab Secondary2</tab-secondary>
                </v-tabs>
            </v-col>
            <v-col cols="12" md="6">
                <v-tabs v-bind="{ ...attrs_tabs_common, ...$attrs }">
                    <tab-tertiary>Tab Tertiary1</tab-tertiary>
                    <tab-tertiary>Tab Tertiary2</tab-tertiary>
                </v-tabs>
            </v-col>
            <v-col cols="12" md="6">
                <v-tabs v-bind="{ ...attrs_tabs_common, ...$attrs }">
                    <tab-quarternary>Tab Quarternary1</tab-quarternary>
                    <tab-quarternary>Tab Quarternary2</tab-quarternary>
                </v-tabs>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Filled -->
        <h2>Filled</h2>
        <p><u>u-tab-filled</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12">
                <v-tabs v-bind="{ ...attrs_tabs_common, ...$attrs }">
                    <u-tab-filled>Tab</u-tab-filled>
                    <u-tab-filled>Tab</u-tab-filled>
                    <u-tab-filled>Tab</u-tab-filled>
                    <u-tab-filled>Tab</u-tab-filled>
                    <u-tab-filled>Tab</u-tab-filled>
                </v-tabs>
            </v-col>
        </v-row>
        <!-- E: Filled -->
        <br />

        <!-- S: Slider -->
        <h2>Slider</h2>
        <p><u>u-tab-slider</u></p>
        <p>u-tabs-slider와 스타일이 다릅니다.</p>
        <br />
        <v-row align="center">
            <v-col cols="12">
                <v-tabs v-bind="{ ...attrs_tabs_common, ...$attrs }">
                    <u-tab-slider>Tab</u-tab-slider>
                    <u-tab-slider>Tab</u-tab-slider>
                    <u-tab-slider>Tab</u-tab-slider>
                    <u-tab-slider>Tab</u-tab-slider>
                    <u-tab-slider>Tab</u-tab-slider>
                </v-tabs>
            </v-col>
        </v-row>
        <!-- E: Slider -->
        <br />

        <!-- S: Dot -->
        <h2>Dot</h2>
        <p><u>u-tabs-dot</u></p>
        <p><u>u-tab-dot</u></p>
        <p>u-tabs-dot, u-tab-dot 세트로 사용합니다.</p>
        <br />
        <v-row align="center">
            <v-col cols="12">
                <u-tabs-dot>
                    <u-tab-dot>Tab</u-tab-dot>
                    <u-tab-dot>Tab</u-tab-dot>
                    <u-tab-dot>Tab</u-tab-dot>
                    <u-tab-dot>Tab</u-tab-dot>
                    <u-tab-dot>Tab</u-tab-dot>
                </u-tabs-dot>
            </v-col>
        </v-row>
        <!-- E: Dot -->
        <br />

        <!-- S: Button -->
        <h2>Button</h2>
        <p><u>u-tab-button</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12">
                <v-tabs v-bind="{ ...attrs_tabs_common, ...$attrs }">
                    <u-tab-button>Tab</u-tab-button>
                    <u-tab-button>Tab</u-tab-button>
                    <u-tab-button>Tab</u-tab-button>
                    <u-tab-button>Tab</u-tab-button>
                    <u-tab-button>Tab</u-tab-button>
                </v-tabs>
            </v-col>
        </v-row>
        <!-- E: Button -->
        <br />

        <!-- S: Punch -->
        <h2>Punch</h2>
        <p><u>u-tab-punch</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12">
                <v-tabs v-bind="{ ...attrs_tabs_common, ...$attrs }">
                    <u-tab-punch>Tab</u-tab-punch>
                    <u-tab-punch>Tab</u-tab-punch>
                    <u-tab-punch>Tab</u-tab-punch>
                    <u-tab-punch>Tab</u-tab-punch>
                    <u-tab-punch>Tab</u-tab-punch>
                </v-tabs>
            </v-col>
        </v-row>
        <!-- E: Punch -->
        <!-- // Styles -->
    </div>
</template>

<script>
import { attrs_tabs_common } from "@/assets/variables";

import GuideApp from "./guide-app.vue";

import TabPrimary from "@/components/publish/parents/tabs/tab-primary.vue";
import TabSecondary from "@/components/publish/parents/tabs/tab-secondary.vue";
import TabTertiary from "@/components/publish/parents/tabs/tab-tertiary.vue";
import TabQuarternary from "@/components/publish/parents/tabs/tab-quarternary.vue";

import UTabFilled from "@/components/publish/styles/tabs/u-tab-filled.vue";
import UTabSlider from "@/components/publish/styles/tabs/u-tab-slider.vue";
import UTabsDot from "@/components/publish/styles/tabs/u-tabs-dot.vue";
import UTabDot from "@/components/publish/styles/tabs/u-tab-dot.vue";
import UTabButton from "@/components/publish/styles/tabs/u-tab-button.vue";
import UTabPunch from "@/components/publish/styles/tabs/u-tab-punch.vue";

export default {
    props: {},
    components: {
        GuideApp,

        TabPrimary,
        TabSecondary,
        TabTertiary,
        TabQuarternary,

        UTabFilled,
        UTabSlider,
        UTabsDot,
        UTabDot,
        UTabButton,
        UTabPunch,
    },
    data: () => {
        return {
            attrs_tabs_common,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
