var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('tit-wrap-primary', [_vm._v("제목을입력해주세요")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('tit-wrap-secondary', {
    scopedSlots: _vm._u([{
      key: "TxtAdd",
      fn: function () {
        return [_vm._v("추가설명을입력해주세요")];
      },
      proxy: true
    }])
  }, [_vm._v(" 제목을입력해주세요 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('tit-wrap-tertiary', {
    scopedSlots: _vm._u([{
      key: "TxtAdd",
      fn: function () {
        return [_vm._v("추가설명을입력해주세요")];
      },
      proxy: true
    }])
  }, [_vm._v(" 제목을입력해주세요 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('tit-wrap-quarternary', {
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v("소제목을입력해주세요")];
      },
      proxy: true
    }])
  }, [_vm._v(" 제목을입력해주세요 ")])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _c('h2', [_vm._v("Default")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('p', [_c('u', [_vm._v("u-tit-wrap-default")])]), _c('br'), _c('u-tit-wrap-default', [_c('u-tit-default', [_vm._v("제목을입력해주세요")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('p', [_c('u', [_vm._v("u-tit-wrap-default")])]), _c('br'), _c('u-tit-wrap-default', [_c('u-tit-default', [_vm._v("제목을입력해주세요")]), _c('u-txt-default', [_vm._v("추가설명을입력해주세요")])], 1)], 1)], 1), _c('br'), _c('h2', [_vm._v("Line")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('p', [_c('u', [_vm._v("u-tit-wrap-line")])]), _c('br'), _c('u-tit-wrap-line', [_c('u-tit-default', [_vm._v("제목을입력해주세요")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('p', [_c('u', [_vm._v("u-tit-wrap-line")])]), _c('br'), _c('u-tit-wrap-line', [_c('u-tit-default', [_vm._v("제목을입력해주세요")]), _c('u-txt-default', [_vm._v("추가설명을입력해주세요")])], 1)], 1)], 1), _c('br'), _c('h2', [_vm._v("Underline")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('p', [_c('u', [_vm._v("u-tit-wrap-underline")])]), _c('br'), _c('u-tit-wrap-underline', [_c('u-tit-default', [_vm._v("제목을입력해주세요")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('p', [_c('u', [_vm._v("u-tit-wrap-underline")])]), _c('br'), _c('u-tit-wrap-underline', [_c('u-tit-default', [_vm._v("제목을입력해주세요")]), _c('u-txt-default', [_vm._v("추가설명을입력해주세요")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Size")]), _c('h3', [_vm._v("{size} : sm, (기본), lg")]), _vm._m(1), _c('br'), _c('tit-wrap', {
    staticClass: "tit-wrap--sm"
  }, [_c('tit', [_vm._v("제목을입력해주세요")])], 1), _c('tit-wrap', [_c('tit', [_vm._v("제목을입력해주세요")])], 1), _c('tit-wrap', {
    staticClass: "tit-wrap--lg"
  }, [_c('tit', [_vm._v("제목을입력해주세요")])], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("tit-wrap-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("tit-wrap--{size}")])]);

}]

export { render, staticRenderFns }