var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-tit-wrap-line', _vm._b({}, 'u-tit-wrap-line', _vm.$attrs, false), [this.$slots['TitAdd'] ? _c('u-tit-subtit', {
    staticClass: "tit--xs"
  }, [_vm._t("TitAdd")], 2) : _vm._e(), _c('u-tit-default', [_vm._t("default")], 2), this.$slots['TxtAdd'] ? _c('u-txt-default', {
    staticClass: "txt--sm"
  }, [_vm._t("TxtAdd")], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }