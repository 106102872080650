var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Size")]), _c('h3', [_vm._v("{size} : xx-small, x-small, small, (기본), large, x-large, xx-large")]), _c('p', [_vm._v("xx-small, xx-large는 클래스로 입력해주세요.")]), _vm._m(0), _vm._m(1), _c('br'), _c('h2', [_vm._v("Default")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-small"
  }, [_vm._v("2Xsmall")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("XSmall")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("Small")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', [_vm._v("Default")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("Large")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "x-large": ""
    }
  }, [_vm._v("XLarge")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large"
  }, [_vm._v("2XLarge")])], 1)], 1), _c('br'), _c('h2', [_vm._v("Text")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-small",
    attrs: {
      "text": ""
    }
  }, [_vm._v("2Xsmall")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "x-small": "",
      "text": ""
    }
  }, [_vm._v("XSmall")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "text": ""
    }
  }, [_vm._v("Small")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    }
  }, [_vm._v("Default")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "text": ""
    }
  }, [_vm._v("Large")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "x-large": "",
      "text": ""
    }
  }, [_vm._v("XLarge")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "text": ""
    }
  }, [_vm._v("2XLarge")])], 1)], 1), _c('br'), _c('h2', [_vm._v("Text fit")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-small v-btn--text-fit"
  }, [_vm._v("2Xsmall")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-fit",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("XSmall")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-fit",
    attrs: {
      "small": ""
    }
  }, [_vm._v("Small")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-fit"
  }, [_vm._v("Default")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-fit",
    attrs: {
      "large": ""
    }
  }, [_vm._v("Large")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-fit",
    attrs: {
      "x-large": ""
    }
  }, [_vm._v("XLarge")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large v-btn--text-fit"
  }, [_vm._v("2XLarge")])], 1)], 1), _c('br'), _c('h2', [_vm._v("Icon")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-small",
    attrs: {
      "icon": ""
    }
  }, [_c('u-icon', [_vm._v("edit")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "x-small": ""
    }
  }, [_c('u-icon', [_vm._v("edit")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    }
  }, [_c('u-icon', [_vm._v("edit")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('u-icon', [_vm._v("edit")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "large": ""
    }
  }, [_c('u-icon', [_vm._v("edit")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "x-large": ""
    }
  }, [_c('u-icon', [_vm._v("edit")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "icon": ""
    }
  }, [_c('u-icon', [_vm._v("edit")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Width")]), _c('p', [_vm._v("block은 입력방법이 다릅니다. 아래 예시를 참조해주세요.")]), _vm._m(2), _vm._m(3), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "min-w-120px min-w-md-200px",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("Custom Width")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "color": "primary"
    }
  }, [_vm._v("Block")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-small v-btn--width-fixed",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("2XS")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--width-fixed",
    attrs: {
      "x-small": "",
      "color": "primary"
    }
  }, [_vm._v("XS")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--width-fixed",
    attrs: {
      "small": "",
      "color": "primary"
    }
  }, [_vm._v("Small")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--width-fixed",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("Default")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--width-fixed",
    attrs: {
      "large": "",
      "color": "primary"
    }
  }, [_vm._v("Large")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--width-fixed",
    attrs: {
      "x-large": "",
      "color": "primary"
    }
  }, [_vm._v("XLarge")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large v-btn--width-fixed",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("2XLarge")])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Color")]), _c('h2', [_vm._v("Default")]), _vm._m(4), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("Primary")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "secondary"
    }
  }, [_vm._v("Secondary")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "accent"
    }
  }, [_vm._v("Accent")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "red"
    }
  }, [_vm._v("Red")])], 1)], 1), _c('br'), _c('h2', [_vm._v("Border")]), _c('p', [_vm._v("outlined일때 테두리색상만 변경하고 싶다면 아래의 방법을 사용해주세요.")]), _vm._m(5), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "secondary",
    attrs: {
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("Primary")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "grey lighten-3",
    attrs: {
      "outlined": "",
      "color": "secondary"
    }
  }, [_vm._v("Secondary")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "grey",
    attrs: {
      "outlined": "",
      "color": "accent"
    }
  }, [_vm._v("Accent")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "primary",
    attrs: {
      "outlined": "",
      "color": "grey"
    }
  }, [_vm._v("Grey")])], 1)], 1), _c('br'), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("State")]), _c('h2', [_vm._v("Disabled")]), _vm._m(6), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "disabled": "",
      "color": "primary"
    }
  }, [_vm._v("Default")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "disabled": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("Outlined")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "disabled": "",
      "text": "",
      "color": "primary"
    }
  }, [_vm._v("Text")])], 1)], 1), _c('br'), _c('h2', [_vm._v("None Active Bg")]), _c('p', [_vm._v("hover, active시 효과가 없습니다.")]), _vm._m(7), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--none-active-bg",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("Default")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--none-active-bg",
    attrs: {
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("Outlined")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--none-active-bg",
    attrs: {
      "text": "",
      "color": "primary"
    }
  }, [_vm._v("Text")])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Rounded")]), _c('h3', [_vm._v("{rounded} : (기본), xs, sm, lg, xl, pill, tile")]), _vm._m(8), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("Default")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("Rounded")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-xs",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("Rounded XS")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-sm",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("Rounded Sm")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("Rounded Lg")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-xl",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("Rounded XL")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-pill",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("Rounded Pill")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "tile": "",
      "color": "primary"
    }
  }, [_vm._v("Tile")])], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "fab": "",
      "color": "primary"
    }
  }, [_c('u-icon', [_vm._v("favorite")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded",
    attrs: {
      "fab": "",
      "color": "primary"
    }
  }, [_c('u-icon', [_vm._v("favorite")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-xs",
    attrs: {
      "fab": "",
      "color": "primary"
    }
  }, [_c('u-icon', [_vm._v("favorite")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-sm",
    attrs: {
      "fab": "",
      "color": "primary"
    }
  }, [_c('u-icon', [_vm._v("favorite")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "fab": "",
      "color": "primary"
    }
  }, [_c('u-icon', [_vm._v("favorite")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-xl",
    attrs: {
      "fab": "",
      "color": "primary"
    }
  }, [_c('u-icon', [_vm._v("favorite")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "fab": "",
      "tile": "",
      "color": "primary"
    }
  }, [_c('u-icon', [_vm._v("favorite")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Icon")]), _c('h3', [_vm._v("{direction} : left, right")]), _c('p', [_vm._v("vue에서 렌더링 하는데 소모하는 시간이 있어 되도록이면 icon=\"\"을 사용하지 않고 u-icon으로 아이콘을 추가합니다.")]), _vm._m(9), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    }
  }, [_c('u-icon', {
    staticClass: "v-icon--left"
  }, [_vm._v("favorite")]), _vm._v(" Prepend Icon ")], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" Append Icon "), _c('u-icon', {
    staticClass: "v-icon--right"
  }, [_vm._v("favorite")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--width-fixed",
    attrs: {
      "color": "primary"
    }
  }, [_c('u-icon', {
    staticClass: "v-icon--left"
  }, [_vm._v("favorite")]), _vm._v(" P Icon ")], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--width-fixed",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" A Icon "), _c('u-icon', {
    staticClass: "v-icon--right"
  }, [_vm._v("favorite")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("{size}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"v-size--{size}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("w-{breakpoint}-{size}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("v-btn--width-fixed")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("color=\"{color}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"{color}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("disabled")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("v-btn--none-active-bg")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"rounded-{rounded}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"v-icon--{direction}\"")])]);

}]

export { render, staticRenderFns }