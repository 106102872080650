var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p', [_vm._v("사용시 tabs-{}, tab-{} 클래스명은 지우고 사용해주세요.")]), _c('p', [_vm._v("템플릿 작업을 위해 넣어 놓은 클래스명입니다.")]), _c('br'), _c('h1', [_vm._v("Styles")]), _c('h2', [_vm._v("Filled")]), _c('br'), _c('div', {
    staticClass: "tabs-wrap"
  }, [_c('u-tabs-filled', [_c('u-tab-filled', {
    staticClass: "tab-filled"
  }, [_vm._v("Tab")]), _c('u-tab-filled', {
    staticClass: "tab-filled"
  }, [_vm._v("Tab")]), _c('u-tab-filled', {
    staticClass: "tab-filled"
  }, [_vm._v("Tab")]), _c('u-tab-filled', {
    staticClass: "tab-filled"
  }, [_vm._v("Tab")]), _c('u-tab-filled', {
    staticClass: "tab-filled"
  }, [_vm._v("Tab")])], 1)], 1), _c('div', {
    staticClass: "tabs-wrap"
  }, [_c('u-tabs-filled', {
    staticClass: "v-tabs--wide-filled tabs-filled2"
  }, [_c('u-tab-filled', {
    staticClass: "tab-filled2"
  }, [_vm._v("Tab")]), _c('u-tab-filled', {
    staticClass: "tab-filled2"
  }, [_vm._v("Tab")]), _c('u-tab-filled', {
    staticClass: "tab-filled2"
  }, [_vm._v("Tab")]), _c('u-tab-filled', {
    staticClass: "tab-filled2"
  }, [_vm._v("Tab")]), _c('u-tab-filled', {
    staticClass: "tab-filled2"
  }, [_vm._v("Tab")])], 1)], 1), _c('div', {
    staticClass: "tabs-wrap"
  }, [_c('u-tabs-filled', {
    staticClass: "v-tabs--wide-filled tabs-filled3"
  }, [_c('u-tab-filled', {
    staticClass: "tab-filled3"
  }, [_vm._v("Tab")]), _c('u-tab-filled', {
    staticClass: "tab-filled3"
  }, [_vm._v("Tab")]), _c('u-tab-filled', {
    staticClass: "tab-filled3"
  }, [_vm._v("Tab")]), _c('u-tab-filled', {
    staticClass: "tab-filled3"
  }, [_vm._v("Tab")]), _c('u-tab-filled', {
    staticClass: "tab-filled3"
  }, [_vm._v("Tab")])], 1)], 1), _c('div', {
    staticClass: "tabs-wrap"
  }, [_c('u-tabs-filled', {
    staticClass: "v-tabs--wide-filled tabs-filled4"
  }, [_c('u-tab-filled', {
    staticClass: "tab-filled4"
  }, [_vm._v("Tab")]), _c('u-tab-filled', {
    staticClass: "tab-filled4"
  }, [_vm._v("Tab")]), _c('u-tab-filled', {
    staticClass: "tab-filled4"
  }, [_vm._v("Tab")]), _c('u-tab-filled', {
    staticClass: "tab-filled4"
  }, [_vm._v("Tab")]), _c('u-tab-filled', {
    staticClass: "tab-filled4"
  }, [_vm._v("Tab")])], 1)], 1), _c('div', {
    staticClass: "tabs-wrap"
  }, [_c('u-tabs-filled', {
    staticClass: "v-tabs--wide-filled tabs-filled5"
  }, [_c('u-tab-filled', {
    staticClass: "tab-filled5"
  }, [_vm._v("Tab")]), _c('u-tab-filled', {
    staticClass: "tab-filled5"
  }, [_vm._v("Tab")]), _c('u-tab-filled', {
    staticClass: "tab-filled5"
  }, [_vm._v("Tab")]), _c('u-tab-filled', {
    staticClass: "tab-filled5"
  }, [_vm._v("Tab")]), _c('u-tab-filled', {
    staticClass: "tab-filled5"
  }, [_vm._v("Tab")])], 1)], 1), _c('br'), _c('h2', [_vm._v("Slider")]), _c('br'), _c('div', {
    staticClass: "tabs-wrap"
  }, [_c('u-tabs-slider', [_c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")])], 1)], 1), _c('div', {
    staticClass: "tabs-wrap"
  }, [_c('u-tabs-border', {
    staticClass: "v-tabs--wide-border",
    attrs: {
      "centered": "",
      "grow": false
    }
  }, [_c('u-tab-slider', [_vm._v("Tab")]), _c('u-tab-slider', [_vm._v("Tab")]), _c('u-tab-slider', [_vm._v("Tab")]), _c('u-tab-slider', [_vm._v("Tab")]), _c('u-tab-slider', [_vm._v("Tab")])], 1)], 1), _c('div', {
    staticClass: "tabs-wrap"
  }, [_c('u-tabs-slider', {
    staticClass: "v-tabs--wide-filled tabs-slider",
    attrs: {
      "color": "white"
    }
  }, [_c('v-tab', {
    staticClass: "tab-slider"
  }, [_vm._v("Tab")]), _c('v-tab', {
    staticClass: "tab-slider"
  }, [_vm._v("Tab")]), _c('v-tab', {
    staticClass: "tab-slider"
  }, [_vm._v("Tab")]), _c('v-tab', {
    staticClass: "tab-slider"
  }, [_vm._v("Tab")]), _c('v-tab', {
    staticClass: "tab-slider"
  }, [_vm._v("Tab")])], 1)], 1), _c('div', {
    staticClass: "tabs-wrap"
  }, [_c('u-tabs-filled', {
    staticClass: "v-tabs--wide-filled tabs-slider2",
    attrs: {
      "centered": "",
      "grow": false,
      "color": "white"
    }
  }, [_c('u-tab-slider', {
    staticClass: "tab-slider2"
  }, [_vm._v("Tab")]), _c('u-tab-slider', {
    staticClass: "tab-slider2"
  }, [_vm._v("Tab")]), _c('u-tab-slider', {
    staticClass: "tab-slider2"
  }, [_vm._v("Tab")]), _c('u-tab-slider', {
    staticClass: "tab-slider2"
  }, [_vm._v("Tab")]), _c('u-tab-slider', {
    staticClass: "tab-slider2"
  }, [_vm._v("Tab")])], 1)], 1), _c('br'), _c('h2', [_vm._v("Dot")]), _c('br'), _c('div', {
    staticClass: "tabs-wrap"
  }, [_c('u-tabs-dot', {
    staticClass: "v-tabs--wide-border"
  }, [_c('u-tab-dot', [_vm._v("Tab")]), _c('u-tab-dot', [_vm._v("Tab")]), _c('u-tab-dot', [_vm._v("Tab")]), _c('u-tab-dot', [_vm._v("Tab")]), _c('u-tab-dot', [_vm._v("Tab")])], 1)], 1), _c('br'), _c('h2', [_vm._v("Button")]), _c('br'), _c('div', {
    staticClass: "tabs-wrap"
  }, [_c('v-tabs', _vm._b({}, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common, _vm.$attrs), false), [_c('u-tab-button', [_vm._v("Tab")]), _c('u-tab-button', [_vm._v("Tab")]), _c('u-tab-button', [_vm._v("Tab")]), _c('u-tab-button', [_vm._v("Tab")]), _c('u-tab-button', [_vm._v("Tab")])], 1)], 1), _c('div', {
    staticClass: "tabs-wrap"
  }, [_c('u-tabs-border', {
    staticClass: "pb-10px pb-md-16px"
  }, [_c('u-tab-button', {
    staticClass: "tab-button"
  }, [_vm._v("Tab")]), _c('u-tab-button', {
    staticClass: "tab-button"
  }, [_vm._v("Tab")]), _c('u-tab-button', {
    staticClass: "tab-button"
  }, [_vm._v("Tab")]), _c('u-tab-button', {
    staticClass: "tab-button"
  }, [_vm._v("Tab")]), _c('u-tab-button', {
    staticClass: "tab-button"
  }, [_vm._v("Tab")])], 1)], 1), _c('br'), _c('h2', [_vm._v("Punch")]), _c('br'), _c('div', {
    staticClass: "tabs-wrap"
  }, [_c('v-tabs', _vm._b({}, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common, _vm.$attrs), false), [_c('u-tab-punch', [_vm._v("Tab")]), _c('u-tab-punch', [_vm._v("Tab")]), _c('u-tab-punch', [_vm._v("Tab")]), _c('u-tab-punch', [_vm._v("Tab")]), _c('u-tab-punch', [_vm._v("Tab")])], 1)], 1), _c('div', {
    staticClass: "tabs-wrap"
  }, [_c('u-tabs-filled', [_c('u-tab-punch', {
    staticClass: "tab-punch"
  }, [_vm._v("Tab")]), _c('u-tab-punch', {
    staticClass: "tab-punch"
  }, [_vm._v("Tab")]), _c('u-tab-punch', {
    staticClass: "tab-punch"
  }, [_vm._v("Tab")]), _c('u-tab-punch', {
    staticClass: "tab-punch"
  }, [_vm._v("Tab")]), _c('u-tab-punch', {
    staticClass: "tab-punch"
  }, [_vm._v("Tab")])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }