<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>card-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <card-primary>
                    <v-card-text>Card Primary Text</v-card-text>
                    <v-card-actions>Card Primary Actions</v-card-actions>
                </card-primary>
            </v-col>
            <v-col cols="auto">
                <card-secondary color="primary">
                    <v-card-text>Card Secondary</v-card-text>
                </card-secondary>
            </v-col>
            <v-col cols="auto">
                <card-tertiary>
                    <v-card-text>Card Tertiary</v-card-text>
                </card-tertiary>
            </v-col>
            <v-col cols="auto">
                <card-quarternary>
                    <v-card-text>Card Quarternary</v-card-text>
                </card-quarternary>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Elevated -->
        <h2>Elevated</h2>
        <p><u>u-card-elevated-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-card-elevated-tile>
                    <v-card-text>Elevated Tile</v-card-text>
                </u-card-elevated-tile>
            </v-col>
            <v-col cols="auto">
                <u-card-elevated-rounded>
                    <v-card-text>Elevated Rounded</v-card-text>
                </u-card-elevated-rounded>
            </v-col>
        </v-row>
        <!-- E: Elevated -->
        <br />

        <!-- S: Flat -->
        <h2>Flat</h2>
        <p><u>u-card-flat-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-card-flat-tile color="primary">
                    <v-card-text>Flat Tile</v-card-text>
                </u-card-flat-tile>
            </v-col>
            <v-col cols="auto">
                <u-card-flat-rounded color="secondary">
                    <v-card-text>Flat Rounded</v-card-text>
                </u-card-flat-rounded>
            </v-col>
        </v-row>
        <!-- E: Flat -->
        <br />

        <!-- S: Outlined -->
        <h2>Outlined</h2>
        <p><u>u-card-outlined-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-card-outlined-tile color="primary">
                    <v-card-text>Outlined Tile</v-card-text>
                </u-card-outlined-tile>
            </v-col>
            <v-col cols="auto">
                <u-card-outlined-rounded color="secondary">
                    <v-card-text>Outlined Rounded</v-card-text>
                </u-card-outlined-rounded>
            </v-col>
        </v-row>
        <!-- E: Outlined -->
        <br />

        <!-- S: Border -->
        <h2>Border</h2>
        <p><u>u-card-border-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-card-border-top>
                    <v-card-text>Border Top</v-card-text>
                </u-card-border-top>
            </v-col>
            <v-col cols="auto">
                <u-card-border-top-outlined>
                    <v-card-text>Border Top Outlined</v-card-text>
                </u-card-border-top-outlined>
            </v-col>
            <v-col cols="auto">
                <u-card-border-bottom>
                    <v-card-text>Border Bottom</v-card-text>
                </u-card-border-bottom>
            </v-col>
        </v-row>
        <!-- E: Border -->
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Color -->
        <h1>Color</h1>
        <p><u>color="{color}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12" md="3">
                <v-card color="grey">
                    <div class="pa-20px">Card Grey</div>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card color="primary">
                    <div class="pa-20px">Card Primary</div>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card color="secondary">
                    <div class="pa-20px">Card Secondary</div>
                </v-card>
            </v-col>
        </v-row>
        <!-- // Color -->

        <v-divider class="my-30px" />

        <!-- Border -->
        <h1>Border</h1>
        <h3>{border} : 0 ~ 10</h3>
        <p><u>outlined class="border-{border}"</u></p>
        <p><u>class="{color}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12" md="3">
                <v-card outlined>
                    <div class="pa-20px">Card Border</div>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card outlined class="border-2">
                    <div class="pa-20px">Card Border2</div>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card outlined class="border-10">
                    <div class="pa-20px">Card Border10</div>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card outlined class="border-color primary">
                    <div class="pa-20px">Card Border10 Primary</div>
                </v-card>
            </v-col>
        </v-row>
        <!-- // Border -->

        <v-divider class="my-30px" />

        <!-- Elevation -->
        <h1>Elevation</h1>
        <h3>{elevation} : 0 ~ 24</h3>
        <p><u>elevation="{elevation}"</u></p>
        <p><u>flat</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12" md="3">
                <v-card flat>
                    <div class="pa-20px">Card Flat</div>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card elevation="2">
                    <div class="pa-20px">Card Elevation2</div>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card elevation="5">
                    <div class="pa-20px">Card Elevation5</div>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card elevation="10">
                    <div class="pa-20px">Card Elevation10</div>
                </v-card>
            </v-col>
        </v-row>
        <!-- // Elevation -->

        <v-divider class="my-30px" />

        <!-- Rounded -->
        <h1>Rounded</h1>
        <h3>{rounded} : (), xs, sm, lg, xl, pill, 0</h3>
        <p><u>class="rounded-{rounded}"</u></p>
        <p><u>shaped, tile</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12" md="3">
                <v-card rounded class="primary">
                    <div class="pa-20px">Card Rounded</div>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card rounded="xs" class="primary">
                    <div class="pa-20px">Card XS</div>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card rounded="sm" class="primary">
                    <div class="pa-20px">Card Sm</div>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card rounded="lg" class="primary">
                    <div class="pa-20px">Card Lg</div>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card rounded="xl" class="primary">
                    <div class="pa-20px">Card XL</div>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card rounded="pill" class="primary">
                    <div class="pa-20px">Card Pill</div>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card rounded="0" class="primary">
                    <div class="pa-20px">Card 0</div>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card shaped class="primary">
                    <div class="pa-20px">Card Shaped</div>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card tile class="primary">
                    <div class="pa-20px">Card Tile</div>
                </v-card>
            </v-col>
        </v-row>
        <!-- // Rounded -->

        <v-divider class="my-30px" />

        <!-- Variant -->
        <h1>Variant</h1>
        <h3>{variant} : (기본-elevation), flat, outlined</h3>
        <p><u>{variant}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12" md="3">
                <v-card elevation="2">
                    <div class="pa-20px">Card Elevation</div>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card flat color="primary">
                    <div class="pa-20px">Card Flat</div>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card outlined>
                    <div class="pa-20px">Card Outlined</div>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card color="primary" outlined>
                    <div class="pa-20px">Card Color Outlined</div>
                </v-card>
            </v-col>
        </v-row>
        <!-- // Variant -->
    </div>
</template>

<script>
import GuideApp from "./guide-app.vue";

import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import CardSecondary from "@/components/publish/parents/cards/card-secondary.vue";
import CardTertiary from "@/components/publish/parents/cards/card-tertiary.vue";
import CardQuarternary from "@/components/publish/parents/cards/card-quarternary.vue";

import UCardElevatedTile from "@/components/publish/styles/cards/u-card-elevated-tile.vue";
import UCardElevatedRounded from "@/components/publish/styles/cards/u-card-elevated-rounded.vue";
import UCardFlatTile from "@/components/publish/styles/cards/u-card-flat-tile.vue";
import UCardFlatRounded from "@/components/publish/styles/cards/u-card-flat-rounded.vue";
import UCardOutlinedTile from "@/components/publish/styles/cards/u-card-outlined-tile.vue";
import UCardOutlinedRounded from "@/components/publish/styles/cards/u-card-outlined-rounded.vue";
import UCardBorderTop from "@/components/publish/styles/cards/u-card-border-top.vue";
import UCardBorderTopOutlined from "@/components/publish/styles/cards/u-card-border-top-outlined.vue";
import UCardBorderBottom from "@/components/publish/styles/cards/u-card-border-bottom.vue";

export default {
    props: {},
    components: {
        GuideApp,

        CardPrimary,
        CardSecondary,
        CardTertiary,
        CardQuarternary,

        UCardElevatedTile,
        UCardElevatedRounded,
        UCardFlatTile,
        UCardFlatRounded,
        UCardOutlinedTile,
        UCardOutlinedRounded,
        UCardBorderTop,
        UCardBorderTopOutlined,
        UCardBorderBottom,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
