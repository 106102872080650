var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab', _vm._b({
    staticClass: "v-tab--slider"
  }, 'v-tab', _vm.$attrs, false), [_c('span', [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }