<template>
    <div>
        <v-tabs v-model="tab" grow class="mb-30px">
            <v-tab>Tabs, Windows</v-tab>
            <v-tab>Tab</v-tab>
            <v-tab>Template</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item><tabs-tabs /></v-tab-item>
            <v-tab-item><tabs-tab /></v-tab-item>
            <v-tab-item><tabs-template /></v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import GuideApp from "./guide-app.vue";

import TabsTabs from "./tabs-tabs.vue";
import TabsTab from "./tabs-tab.vue";
import TabsTemplate from "./tabs-template.vue";

export default {
    props: {},
    components: {
        GuideApp,

        TabsTabs,
        TabsTab,
        TabsTemplate,
    },
    data() {
        return {
            tab: null,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>