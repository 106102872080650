<template>
    <u-tabs-dot v-model="TabsTertiaryValue" v-bind="$attrs" @change="TabsTertiaryValueChange" class="v-tabs--border">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-tabs-dot>
</template>

<script>
import UTabsDot from "@/components/publish/styles/tabs/u-tabs-dot.vue";

export default {
    props: {
        value: { type: [Number, String] },
    },
    components: {
        UTabsDot,
    },
    data() {
        return {
        };
    },
    computed: {
        TabsTertiaryValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("change", newValue);
            },
        },
    },
    mounted() {},
    methods: {
        TabsTertiaryValueChange(newValue) {
            this.TabsTertiaryValue = newValue;
        },
    },
};
</script>

<style lang="scss" scoped></style>
