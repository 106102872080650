var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    staticClass: "card-gradient",
    attrs: {
      "rounded": "sm",
      "color": "transparent"
    }
  }, 'v-card', _vm.$attrs, false), [_c('span', {
    staticClass: "card-gradient__line card-gradient__line--1"
  }), _c('span', {
    staticClass: "card-gradient__line card-gradient__line--2"
  }), _c('div', {
    staticClass: "pa-20px pa-md-30px pa-lg-40px"
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }