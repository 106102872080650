var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('v-container', [_c('guide-common'), _c('v-tabs', {
    staticClass: "mt-60px mb-30px",
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("Utilities")]), _c('v-tab', [_vm._v("Colors")]), _c('v-tab', [_vm._v("Typography")]), _c('v-tab', [_vm._v("Buttons")]), _c('v-tab', [_vm._v("Chips")]), _c('v-tab', [_vm._v("Icons")]), _c('v-tab', [_vm._v("Tabs")]), _c('v-tab', [_vm._v("Cards")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('utilities')], 1), _c('v-tab-item', [_c('colors')], 1), _c('v-tab-item', [_c('typography')], 1), _c('v-tab-item', [_c('buttons')], 1), _c('v-tab-item', [_c('chips')], 1), _c('v-tab-item', [_c('icons')], 1), _c('v-tab-item', [_c('tabs')], 1), _c('v-tab-item', [_c('cards')], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }