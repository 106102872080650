<template>
    <btn-hamburger @click="$emit('click')"><slot /></btn-hamburger>
</template>

<script>
import BtnHamburger from "@/components/publish/styles/buttons/btn-hamburger.vue";

export default {
    props: {},
    components: {
        BtnHamburger,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
