<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>btn-top-primary</u></p>
        <br />
        <div>
            <!-- <btn-top-primary /> -->
        </div>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <p>top버튼은 보통 하나만 사용하므로 styles컴포넌트를 따로 만들지 않고 가이드에서 스타일을 복사해서 사용합니다.</p>
        <br />
        <!-- S: Tile -->
        <h2>Tile</h2>
        <v-row align="center">
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <u-btn-icon-outlined-tile size="large" color="primary"><icon-chevron size="small" direction="up" /></u-btn-icon-outlined-tile>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-tile size="large" color="primary"><icon-chevron size="small" direction="up" /></u-btn-icon-flat-tile>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-tile size="large" color="grey-darken-4" class="border-color border-grey-lighten-3"><icon-chevron size="small" direction="up" /></u-btn-icon-outlined-tile>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-tile size="large" color="grey-darken-4"><icon-chevron size="small" direction="up" /></u-btn-icon-outlined-tile>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-tile size="large" color="white" elevation="4"><icon-chevron size="small" direction="up" /></u-btn-icon-flat-tile>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <u-btn-icon-outlined-tile size="large" color="primary" class="border-2"><icon-arrow-rounded size="small" direction="up" /></u-btn-icon-outlined-tile>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-tile size="large" color="primary"><icon-arrow-rounded size="small" direction="up" /></u-btn-icon-flat-tile>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-tile size="large" color="grey-darken-4" class="border-color border-grey-lighten-3"><icon-arrow-rounded size="small" direction="up" /></u-btn-icon-outlined-tile>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-tile size="large" color="grey-darken-4" class="border-2"><icon-arrow-rounded size="small" direction="up" /></u-btn-icon-outlined-tile>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-tile size="large" color="white" elevation="4"><icon-arrow-rounded size="small" direction="up" /></u-btn-icon-flat-tile>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <u-btn-icon-outlined-tile size="large" color="primary"><icon-arrow-long size="small" direction="up" /></u-btn-icon-outlined-tile>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-tile size="large" color="primary"><icon-arrow-long size="small" direction="up" /></u-btn-icon-flat-tile>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-tile size="large" color="grey-darken-4" class="border-color border-grey-lighten-3"><icon-arrow-long size="small" direction="up" /></u-btn-icon-outlined-tile>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-tile size="large" color="grey-darken-4"><icon-arrow-long size="small" direction="up" /></u-btn-icon-outlined-tile>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-tile size="large" color="white" elevation="4"><icon-arrow-long size="small" direction="up" /></u-btn-icon-flat-tile>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <u-btn-icon-outlined-tile size="large" color="primary" class="border-2"><b class="font-size-14">Top</b></u-btn-icon-outlined-tile>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-tile size="large" color="primary"><span class="font-size-14">Top</span></u-btn-icon-outlined-tile>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-tile size="large" color="primary"><span class="font-size-14">Top</span></u-btn-icon-flat-tile>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-tile size="large" color="grey-darken-4" class="border-color border-grey-lighten-3"><span class="font-size-14">Top</span></u-btn-icon-outlined-tile>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-tile size="large" color="grey-darken-4" class="border-2 border-color border-grey-lighten-3"><b class="font-size-14">Top</b></u-btn-icon-outlined-tile>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-tile size="large" color="white" elevation="4"><span class="font-size-14">Top</span></u-btn-icon-flat-tile>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <u-btn-icon-outlined-tile size="large" color="primary">
                            <div class="line-height-0">
                                <icon-chevron size="xx-small" direction="up" />
                                <div class="font-size-12 mt-2px line-height-1">TOP</div>
                            </div>
                        </u-btn-icon-outlined-tile>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-tile size="large" color="primary">
                            <div class="line-height-0">
                                <icon-chevron size="xx-small" direction="up" />
                                <div class="font-size-12 mt-2px line-height-1">TOP</div>
                            </div>
                        </u-btn-icon-flat-tile>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-tile size="large" color="grey-darken-4" class="border-color border-grey-lighten-3">
                            <div class="line-height-0">
                                <icon-chevron size="xx-small" direction="up" />
                                <div class="font-size-12 mt-2px line-height-1">TOP</div>
                            </div>
                        </u-btn-icon-outlined-tile>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-tile size="large" color="grey-darken-4">
                            <div class="line-height-0">
                                <icon-chevron size="xx-small" direction="up" />
                                <div class="font-size-12 mt-2px line-height-1">TOP</div>
                            </div>
                        </u-btn-icon-outlined-tile>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-tile size="large" color="white" elevation="4">
                            <div class="line-height-0">
                                <icon-chevron size="xx-small" direction="up" />
                                <div class="font-size-12 mt-2px line-height-1">TOP</div>
                            </div>
                        </u-btn-icon-flat-tile>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- E: Tile -->
        <br />

        <!-- S: Rounded -->
        <h2>Rounded</h2>
        <v-row align="center">
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <u-btn-icon-outlined-rounded size="large" color="primary"><icon-chevron size="small" direction="up" /></u-btn-icon-outlined-rounded>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-rounded size="large" color="primary"><icon-chevron size="small" direction="up" /></u-btn-icon-flat-rounded>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-rounded size="large" color="grey-darken-4" class="border-color border-grey-lighten-3"><icon-chevron size="small" direction="up" /></u-btn-icon-outlined-rounded>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-rounded size="large" color="grey-darken-4"><icon-chevron size="small" direction="up" /></u-btn-icon-outlined-rounded>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-rounded size="large" color="white" elevation="4"><icon-chevron size="small" direction="up" /></u-btn-icon-flat-rounded>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <u-btn-icon-outlined-rounded size="large" color="primary" class="border-2"><icon-arrow-rounded size="small" direction="up" /></u-btn-icon-outlined-rounded>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-rounded size="large" color="primary"><icon-arrow-rounded size="small" direction="up" /></u-btn-icon-flat-rounded>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-rounded size="large" color="grey-darken-4" class="border-color border-grey-lighten-3"><icon-arrow-rounded size="small" direction="up" /></u-btn-icon-outlined-rounded>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-rounded size="large" color="grey-darken-4" class="border-2"><icon-arrow-rounded size="small" direction="up" /></u-btn-icon-outlined-rounded>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-rounded size="large" color="white" elevation="4"><icon-arrow-rounded size="small" direction="up" /></u-btn-icon-flat-rounded>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <u-btn-icon-outlined-rounded size="large" color="primary"><icon-arrow-long size="small" direction="up" /></u-btn-icon-outlined-rounded>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-rounded size="large" color="primary"><icon-arrow-long size="small" direction="up" /></u-btn-icon-flat-rounded>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-rounded size="large" color="grey-darken-4" class="border-color border-grey-lighten-3"><icon-arrow-long size="small" direction="up" /></u-btn-icon-outlined-rounded>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-rounded size="large" color="grey-darken-4"><icon-arrow-long size="small" direction="up" /></u-btn-icon-outlined-rounded>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-rounded size="large" color="white" elevation="4"><icon-arrow-long size="small" direction="up" /></u-btn-icon-flat-rounded>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <u-btn-icon-outlined-rounded size="large" color="primary" class="border-2"><b class="font-size-14">Top</b></u-btn-icon-outlined-rounded>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-rounded size="large" color="primary"><span class="font-size-14">Top</span></u-btn-icon-outlined-rounded>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-rounded size="large" color="primary"><span class="font-size-14">Top</span></u-btn-icon-flat-rounded>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-rounded size="large" color="grey-darken-4" class="border-color border-grey-lighten-3"><span class="font-size-14">Top</span></u-btn-icon-outlined-rounded>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-rounded size="large" color="grey-darken-4" class="border-2 border-color border-grey-lighten-3"><b class="font-size-14">Top</b></u-btn-icon-outlined-rounded>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-rounded size="large" color="white" elevation="4"><span class="font-size-14">Top</span></u-btn-icon-flat-rounded>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <u-btn-icon-outlined-rounded size="large" color="primary">
                            <div class="line-height-0">
                                <icon-chevron size="xx-small" direction="up" />
                                <div class="font-size-12 mt-2px line-height-1">TOP</div>
                            </div>
                        </u-btn-icon-outlined-rounded>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-rounded size="large" color="primary">
                            <div class="line-height-0">
                                <icon-chevron size="xx-small" direction="up" />
                                <div class="font-size-12 mt-2px line-height-1">TOP</div>
                            </div>
                        </u-btn-icon-flat-rounded>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-rounded size="large" color="grey-darken-4" class="border-color border-grey-lighten-3">
                            <div class="line-height-0">
                                <icon-chevron size="xx-small" direction="up" />
                                <div class="font-size-12 mt-2px line-height-1">TOP</div>
                            </div>
                        </u-btn-icon-outlined-rounded>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-rounded size="large" color="grey-darken-4">
                            <div class="line-height-0">
                                <icon-chevron size="xx-small" direction="up" />
                                <div class="font-size-12 mt-2px line-height-1">TOP</div>
                            </div>
                        </u-btn-icon-outlined-rounded>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-rounded size="large" color="white" elevation="4">
                            <div class="line-height-0">
                                <icon-chevron size="xx-small" direction="up" />
                                <div class="font-size-12 mt-2px line-height-1">TOP</div>
                            </div>
                        </u-btn-icon-flat-rounded>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- E: Rounded -->
        <br />

        <!-- S: Circle -->
        <h2>Circle</h2>
        <v-row align="center">
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <u-btn-icon-outlined-circle size="large" color="primary"><icon-chevron size="small" direction="up" /></u-btn-icon-outlined-circle>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-circle size="large" color="primary"><icon-chevron size="small" direction="up" /></u-btn-icon-flat-circle>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-circle size="large" color="grey-darken-4" class="border-color border-grey-lighten-3"><icon-chevron size="small" direction="up" /></u-btn-icon-outlined-circle>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-circle size="large" color="grey-darken-4"><icon-chevron size="small" direction="up" /></u-btn-icon-outlined-circle>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-circle size="large" color="white" elevation="4"><icon-chevron size="small" direction="up" /></u-btn-icon-flat-circle>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <u-btn-icon-outlined-circle size="large" color="primary" class="border-2"><icon-arrow-rounded size="small" direction="up" /></u-btn-icon-outlined-circle>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-circle size="large" color="primary"><icon-arrow-rounded size="small" direction="up" /></u-btn-icon-flat-circle>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-circle size="large" color="grey-darken-4" class="border-color border-grey-lighten-3"><icon-arrow-rounded size="small" direction="up" /></u-btn-icon-outlined-circle>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-circle size="large" color="grey-darken-4" class="border-2"><icon-arrow-rounded size="small" direction="up" /></u-btn-icon-outlined-circle>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-circle size="large" color="white" elevation="4"><icon-arrow-rounded size="small" direction="up" /></u-btn-icon-flat-circle>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <u-btn-icon-outlined-circle size="large" color="primary"><icon-arrow-long size="small" direction="up" /></u-btn-icon-outlined-circle>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-circle size="large" color="primary"><icon-arrow-long size="small" direction="up" /></u-btn-icon-flat-circle>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-circle size="large" color="grey-darken-4" class="border-color border-grey-lighten-3"><icon-arrow-long size="small" direction="up" /></u-btn-icon-outlined-circle>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-circle size="large" color="grey-darken-4"><icon-arrow-long size="small" direction="up" /></u-btn-icon-outlined-circle>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-circle size="large" color="white" elevation="4"><icon-arrow-long size="small" direction="up" /></u-btn-icon-flat-circle>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <u-btn-icon-outlined-circle size="large" color="primary" class="border-2"><b class="font-size-14">Top</b></u-btn-icon-outlined-circle>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-circle size="large" color="primary"><span class="font-size-14">Top</span></u-btn-icon-outlined-circle>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-circle size="large" color="primary"><span class="font-size-14">Top</span></u-btn-icon-flat-circle>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-circle size="large" color="grey-darken-4" class="border-color border-grey-lighten-3"><span class="font-size-14">Top</span></u-btn-icon-outlined-circle>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-circle size="large" color="grey-darken-4" class="border-2 border-color border-grey-lighten-3"><b class="font-size-14">Top</b></u-btn-icon-outlined-circle>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-circle size="large" color="white" elevation="4"><span class="font-size-14">Top</span></u-btn-icon-flat-circle>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row align="center">
                    <v-col cols="auto">
                        <u-btn-icon-outlined-circle size="large" color="primary">
                            <div class="line-height-0">
                                <icon-chevron size="xx-small" direction="up" />
                                <div class="font-size-12 mt-2px line-height-1">TOP</div>
                            </div>
                        </u-btn-icon-outlined-circle>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-circle size="large" color="primary">
                            <div class="line-height-0">
                                <icon-chevron size="xx-small" direction="up" />
                                <div class="font-size-12 mt-2px line-height-1">TOP</div>
                            </div>
                        </u-btn-icon-flat-circle>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-circle size="large" color="grey-darken-4" class="border-color border-grey-lighten-3">
                            <div class="line-height-0">
                                <icon-chevron size="xx-small" direction="up" />
                                <div class="font-size-12 mt-2px line-height-1">TOP</div>
                            </div>
                        </u-btn-icon-outlined-circle>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-outlined-circle size="large" color="grey-darken-4">
                            <div class="line-height-0">
                                <icon-chevron size="xx-small" direction="up" />
                                <div class="font-size-12 mt-2px line-height-1">TOP</div>
                            </div>
                        </u-btn-icon-outlined-circle>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-icon-flat-circle size="large" color="white" elevation="4">
                            <div class="line-height-0">
                                <icon-chevron size="xx-small" direction="up" />
                                <div class="font-size-12 mt-2px line-height-1">TOP</div>
                            </div>
                        </u-btn-icon-flat-circle>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- E: Circle -->
        <!-- // Styles -->
    </div>
</template>

<script>
import BtnTopPrimary from "@/components/publish/parents/buttons/btn-top-primary.vue";
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";
import IconArrowSecondary from "@/components/publish/parents/icons/icon-arrow-secondary.vue";
import IconArrowTertiary from "@/components/publish/parents/icons/icon-arrow-tertiary.vue";
import IconArrowQuarternary from "@/components/publish/parents/icons/icon-arrow-quarternary.vue";

import UBtnIconFlatTile from "@/components/publish/styles/buttons/u-btn-icon-flat-tile.vue";
import UBtnIconFlatRounded from "@/components/publish/styles/buttons/u-btn-icon-flat-rounded.vue";
import UBtnIconFlatCircle from "@/components/publish/styles/buttons/u-btn-icon-flat-circle.vue";
import UBtnIconOutlinedTile from "@/components/publish/styles/buttons/u-btn-icon-outlined-tile.vue";
import UBtnIconOutlinedRounded from "@/components/publish/styles/buttons/u-btn-icon-outlined-rounded.vue";
import UBtnIconOutlinedCircle from "@/components/publish/styles/buttons/u-btn-icon-outlined-circle.vue";

import UIcon from "@/components/publish/styles/icons/u-icon.vue";
import IconChevron from "@/components/publish/styles/icons/icon-chevron.vue";
import IconArrow from "@/components/publish/styles/icons/icon-arrow.vue";
import IconArrowRounded from "@/components/publish/styles/icons/icon-arrow-rounded.vue";
import IconArrowLong from "@/components/publish/styles/icons/icon-arrow-long.vue";
import IconArrowLongRounded from "@/components/publish/styles/icons/icon-arrow-long-rounded.vue";

export default {
    props: {},
    components: {
        BtnTopPrimary,
        IconArrowPrimary,
        IconArrowSecondary,
        IconArrowTertiary,
        IconArrowQuarternary,

        UBtnIconFlatTile,
        UBtnIconFlatRounded,
        UBtnIconFlatCircle,
        UBtnIconOutlinedTile,
        UBtnIconOutlinedRounded,
        UBtnIconOutlinedCircle,

        UIcon,
        IconChevron,
        IconArrow,
        IconArrowRounded,
        IconArrowLong,
        IconArrowLongRounded,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
