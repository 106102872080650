var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('chip-primary', [_vm._v("Chip")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('chip-secondary', {
    attrs: {
      "x-large": ""
    }
  }, [_vm._v("Chip")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('chip-tertiary', [_vm._v(" Chip ")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('chip-quarternary', [_c('u-icon', {
    staticClass: "v-icon--left"
  }, [_vm._v("check")]), _vm._v(" Chip ")], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _c('h2', [_vm._v("Flat")]), _vm._m(1), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-chip-flat-tile', [_vm._v("Flat Tile")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-chip-flat-label', [_vm._v("Flat Label")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-chip-flat-pill', [_vm._v("Flat Pill")])], 1)], 1), _c('br'), _c('h2', [_vm._v("Outlined")]), _vm._m(2), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-chip-outlined-tile', [_vm._v("Outlined Tile")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-chip-outlined-label', [_vm._v("Outlined Label")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-chip-outlined-pill', [_vm._v("Outlined Pill")])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Size")]), _c('h3', [_vm._v("{size} : x-small, small, (기본), large, x-large")]), _vm._m(3), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("XSmall")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("Small")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', [_vm._v("Default")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("Large")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "x-large": ""
    }
  }, [_vm._v("XLarge")])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Color")]), _c('h2', [_vm._v("Default")]), _vm._m(4), _vm._m(5), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("Primary")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "color": "secondary"
    }
  }, [_vm._v("Secondary")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "color": "accent"
    }
  }, [_vm._v("Accent")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "color": "grey",
      "text-color": "secondary lighten-5"
    }
  }, [_vm._v("Grey")])], 1)], 1), _c('br'), _c('h2', [_vm._v("Border")]), _vm._m(6), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "secondary",
    attrs: {
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("Primary")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "accent",
    attrs: {
      "outlined": "",
      "color": "secondary"
    }
  }, [_vm._v("Secondary")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "grey",
    attrs: {
      "outlined": "",
      "color": "accent"
    }
  }, [_vm._v("Accent")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "grey lighten-3",
    attrs: {
      "outlined": "",
      "color": "grey"
    }
  }, [_vm._v("Grey")])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("State")]), _c('h2', [_vm._v("Disabled")]), _vm._m(7), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "disabled": "",
      "color": "primary"
    }
  }, [_vm._v("Default")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "disabled": "",
      "color": "primary"
    }
  }, [_vm._v("Flat")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "disabled": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("Outlined")])], 1)], 1), _c('br'), _c('h2', [_vm._v("Draggable")]), _vm._m(8), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "draggable": "",
      "color": "primary"
    }
  }, [_vm._v("Draggable")])], 1)], 1), _c('br'), _c('h2', [_vm._v("Link")]), _vm._m(9), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "link": "",
      "color": "primary"
    }
  }, [_vm._v("Default")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "link": "",
      "color": "primary"
    }
  }, [_vm._v("Flat")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "link": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("Outlined")])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Variant")]), _c('h3', [_vm._v("{variant} : (기본-flat), outlined")]), _vm._m(10), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', [_vm._v("Default")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "outlined": ""
    }
  }, [_vm._v("Outlined")])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Rounded")]), _c('h3', [_vm._v("{rounded} : (기본-pill), xs, sm, (md), lg, xl, 0")]), _c('p', [_vm._v("label은 입력방법이 다릅니다. 아래 예시를 참조해주세요.")]), _vm._m(11), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("Default")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "rounded-xs",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("Rounded XS")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "rounded-sm",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("Rounded Sm")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "rounded",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("Rounded")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "rounded-lg",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("Rounded Lg")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "rounded-xl",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("Rounded XL")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "rounded-0",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("Tile")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "label": "",
      "color": "primary"
    }
  }, [_vm._v("Label")])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Icon")]), _c('h3', [_vm._v("{direction} : left, right")]), _c('p', [_vm._v("vue에서 렌더링 하는데 소모하는 시간이 있어 되도록이면 icon=\"\"을 사용하지 않고 template v-slot으로 아이콘을 추가합니다.")]), _c('p', [_vm._v("이벤트가 있는 아이콘은 template v-slot으로 사용하면 이벤트가 작동되지 않아 {direction}=\"\"형태로 수정합니다. 이때 아이콘은 mdi아이콘만 사용할 수 있습니다.")]), _vm._m(12), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "color": "primary"
    }
  }, [_c('u-icon', {
    staticClass: "v-icon--left"
  }, [_vm._v("favorite")]), _vm._v(" Prepend Icon ")], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" Append Icon "), _c('u-icon', {
    staticClass: "v-icon--right"
  }, [_vm._v("favorite")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("chip-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-chip-flat-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-chip-outlined-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("{size}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("color=\"{color}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("text-color=\"{color}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"{color}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("disabled")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("draggable")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("link")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("{variant}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"rounded-{rounded}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"v-icon--{direction}\"")])]);

}]

export { render, staticRenderFns }