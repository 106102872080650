<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>btn-hamburger-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <btn-hamburger-primary />
            </v-col>
            <v-col cols="auto" class="grey">
                <btn-hamburger-secondary />
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Basic -->
        <h2>Basic</h2>
        <p><u>u-btn-hamburger-basic</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-btn-hamburger-basic></u-btn-hamburger-basic>
            </v-col>
            <v-col cols="auto">
                <u-btn-hamburger-basic class="active"></u-btn-hamburger-basic>
            </v-col>
        </v-row>
        <!-- E: Basic -->
        <br />

        <!-- S: Concave -->
        <h2>Concave</h2>
        <p><u>u-btn-hamburger-concave</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-btn-hamburger-concave></u-btn-hamburger-concave>
            </v-col>
            <v-col cols="auto">
                <u-btn-hamburger-concave class="active"></u-btn-hamburger-concave>
            </v-col>
        </v-row>
        <!-- E: Concave -->
        <br />
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Color -->
        <h1>Color</h1>
        <p><u>btn-hamburger--light</u></p>
        <br />
        <div class="grey pa-20px">
            <v-row align="center">
                <v-col cols="auto">
                    <u-btn-hamburger-basic class="btn-hamburger--light"></u-btn-hamburger-basic>
                </v-col>
                <v-col cols="auto">
                    <u-btn-hamburger-concave class="btn-hamburger--light"></u-btn-hamburger-concave>
                </v-col>
            </v-row>
        </div>
        <!-- // Color -->
    </div>
</template>

<script>
import BtnHamburgerPrimary from "@/components/publish/parents/buttons/btn-hamburger-primary.vue";
import BtnHamburgerSecondary from "@/components/publish/parents/buttons/btn-hamburger-secondary.vue";

import UBtnHamburgerBasic from "@/components/publish/styles/buttons/u-btn-hamburger-basic.vue";
import UBtnHamburgerConcave from "@/components/publish/styles/buttons/u-btn-hamburger-concave.vue";

export default {
    props: {},
    components: {
        BtnHamburgerPrimary,
        BtnHamburgerSecondary,

        UBtnHamburgerBasic,
        UBtnHamburgerConcave,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
