var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-btn-arrow-outlined-rounded', _vm._b({
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, 'u-btn-arrow-outlined-rounded', _vm.$attrs, false), [_c('icon-arrow-quarternary', _vm._b({}, 'icon-arrow-quarternary', {
    direction: _vm.direction
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }