var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('card-primary', [_c('v-card-text', [_vm._v("Card Primary Text")]), _c('v-card-actions', [_vm._v("Card Primary Actions")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('card-secondary', {
    attrs: {
      "color": "primary"
    }
  }, [_c('v-card-text', [_vm._v("Card Secondary")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('card-tertiary', [_c('v-card-text', [_vm._v("Card Tertiary")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('card-quarternary', [_c('v-card-text', [_vm._v("Card Quarternary")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _c('h2', [_vm._v("Elevated")]), _vm._m(1), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-card-elevated-tile', [_c('v-card-text', [_vm._v("Elevated Tile")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-card-elevated-rounded', [_c('v-card-text', [_vm._v("Elevated Rounded")])], 1)], 1)], 1), _c('br'), _c('h2', [_vm._v("Flat")]), _vm._m(2), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-card-flat-tile', {
    attrs: {
      "color": "primary"
    }
  }, [_c('v-card-text', [_vm._v("Flat Tile")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-card-flat-rounded', {
    attrs: {
      "color": "secondary"
    }
  }, [_c('v-card-text', [_vm._v("Flat Rounded")])], 1)], 1)], 1), _c('br'), _c('h2', [_vm._v("Outlined")]), _vm._m(3), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-card-outlined-tile', {
    attrs: {
      "color": "primary"
    }
  }, [_c('v-card-text', [_vm._v("Outlined Tile")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-card-outlined-rounded', {
    attrs: {
      "color": "secondary"
    }
  }, [_c('v-card-text', [_vm._v("Outlined Rounded")])], 1)], 1)], 1), _c('br'), _c('h2', [_vm._v("Border")]), _vm._m(4), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-card-border-top', [_c('v-card-text', [_vm._v("Border Top")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-card-border-top-outlined', [_c('v-card-text', [_vm._v("Border Top Outlined")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-card-border-bottom', [_c('v-card-text', [_vm._v("Border Bottom")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Color")]), _vm._m(5), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey"
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card Grey")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card Primary")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "secondary"
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card Secondary")])])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Border")]), _c('h3', [_vm._v("{border} : 0 ~ 10")]), _vm._m(6), _vm._m(7), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card Border")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    staticClass: "border-2",
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card Border2")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    staticClass: "border-10",
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card Border10")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    staticClass: "border-color primary",
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card Border10 Primary")])])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Elevation")]), _c('h3', [_vm._v("{elevation} : 0 ~ 24")]), _vm._m(8), _vm._m(9), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card Flat")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "2"
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card Elevation2")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "5"
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card Elevation5")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "10"
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card Elevation10")])])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Rounded")]), _c('h3', [_vm._v("{rounded} : (), xs, sm, lg, xl, pill, 0")]), _vm._m(10), _vm._m(11), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    staticClass: "primary",
    attrs: {
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card Rounded")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    staticClass: "primary",
    attrs: {
      "rounded": "xs"
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card XS")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    staticClass: "primary",
    attrs: {
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card Sm")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    staticClass: "primary",
    attrs: {
      "rounded": "lg"
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card Lg")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    staticClass: "primary",
    attrs: {
      "rounded": "xl"
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card XL")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    staticClass: "primary",
    attrs: {
      "rounded": "pill"
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card Pill")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    staticClass: "primary",
    attrs: {
      "rounded": "0"
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card 0")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    staticClass: "primary",
    attrs: {
      "shaped": ""
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card Shaped")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    staticClass: "primary",
    attrs: {
      "tile": ""
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card Tile")])])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Variant")]), _c('h3', [_vm._v("{variant} : (기본-elevation), flat, outlined")]), _vm._m(12), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "2"
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card Elevation")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card Flat")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card Outlined")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "primary",
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_vm._v("Card Color Outlined")])])], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("card-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-card-elevated-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-card-flat-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-card-outlined-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-card-border-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("color=\"{color}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("outlined class=\"border-{border}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"{color}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("elevation=\"{elevation}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("flat")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"rounded-{rounded}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("shaped, tile")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("{variant}")])]);

}]

export { render, staticRenderFns }