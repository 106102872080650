<template>
    <v-tab v-bind="$attrs" class="v-tab--button">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-tab>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.v-tab--button {
    --tab-margin-compact: 0 6px;
    --tab-margin-comfortable: 0 6px;
    --tab-margin: 0 6px;
    // background-color: transparent !important;
    color: var(--v-grey-base) !important;
    background-color: var(--v-grey-lighten5) !important;
    &,
    &:before {
        border-radius: 999px;
    }
    &.v-tab {
        &:first-child {
            margin-left: auto !important;
        }
        &:last-child {
            margin-right: auto !important;
        }
        &--active {
            color: #fff !important;
            background-color: var(--v-primary-base) !important;
        }
    }
}
</style>
