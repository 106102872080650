var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-btn-text-fit', _vm._b({
    attrs: {
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, 'u-btn-text-fit', _vm.$attrs, false), [_vm._t("default"), _c('u-icon', {
    staticClass: "v-icon--right"
  }, [_vm._v("check")])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }