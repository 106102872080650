<template>
    <div class="text-center">
        <u-tit-subtit data-aos="fade-up" data-aos-delay="200" v-if="this.$slots['TitAdd']"><slot name="TitAdd" /></u-tit-subtit>
        <u-tit-default data-aos="fade-up" class="tit--xl" :class="dark ? 'white--text' : ''"><slot /></u-tit-default>
        <span data-aos="fade-up" data-aos-delay="50" class="line line--lg mx-auto my-20px my-md-40px" :class="!dark ? 'line--dark' : ''"></span>
        <u-txt-default data-aos="fade-up" data-aos-delay="100" v-if="this.$slots['TxtAdd']" class="txt--xl" :class="dark ? 'white--text' : 'txt--dark'"><slot name="TxtAdd" /></u-txt-default>
    </div>
</template>

<script>
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitSubtit from "@/components/publish/styles/typography/u-tit-subtit.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {
        dark: { type: Boolean, default: false },
    },
    components: {
        UTitDefault,
        UTitSubtit,
        UTxtDefault,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
</style>
