<template>
    <svg
        v-if="direction == 'up' || direction == 'down'"
        v-bind="$attrs"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 11 18"
        class="icon-svg icon-arrow-long"
        :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']"
    >
        <path d="M5.5,0L0,5.9l.9,1L4.8,2.7v15.3h1.4V2.7l3.9,4.2,.9-1L5.5,0Z" />
    </svg>
    <svg
        v-else
        v-bind="$attrs"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19 12"
        class="icon-svg icon-arrow-long"
        :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']"
    >
        <path d="M0.5 6L6.4 11.5L7.4 10.6L3.2 6.7L18.5 6.7L18.5 5.3L3.2 5.3L7.4 1.4L6.4 0.5L0.5 6Z" />
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
        direction: { type: String, default: "left" },
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Direction
.icon--direction {
    &-right,
    &-down {
        transform: rotate(180deg);
    }
    &-left,
    &-right {
        // Size
        &.icon--size {
            &-xx-small {
                width: 12px;
            }
            &-x-small {
                width: 14px;
            }
            &-small {
                width: 16px;
            }
            &-default {
                width: 18px;
            }
            &-large {
                width: 20px;
            }
            &-x-large {
                width: 22px;
            }
            &-xx-large {
                width: 24px;
            }
        }
    }
    &-up,
    &-down {
        // Size
        &.icon--size {
            &-xx-small {
                height: 12px;
            }
            &-x-small {
                height: 14px;
            }
            &-small {
                height: 16px;
            }
            &-default {
                height: 18px;
            }
            &-large {
                height: 20px;
            }
            &-x-large {
                height: 22px;
            }
            &-xx-large {
                height: 24px;
            }
        }
    }
}
</style>
